angular.module('llax.services', ['llax.realtimeProvider', 'ngResource'])
.service('Auth', function($q, $rootScope, LogoutResource, OrganizationService, UserDetails, CheckNotifications) {

    var user = null;
    var organization = null;
    var userRoles = null;
    var self = this;

    this.ALL = '*';
    this.ANY = '?';
    this.OBJECT_TYPE_UI = 'ui';
    this.OBJECT_TYPE_ITEMS = 'items';
    this.OBJECT_TYPE_COMMUNICATION_CHANNELS = 'communication_channels';
    this.OBJECT_TYPE_CONTACT = 'contacts';
    this.OBJECT_TYPE_DATAMODEL = 'datamodel';
    this.OBJECT_TYPE_TASK_LISTS = 'taskLists';
    this.OBJECT_TYPE_ENHANCED_CONTENTS = 'enhancedContents';

    this.CONTEXT_DEFAULT_VALUE = "__default_value__";

    this.isLoggedIn = false;

    this.userDetails = function () {
        var deferred = $q.defer();
        if (organization && user) {
            deferred.resolve({organization: organization, user: user, userRoles: userRoles});
        } else {
            UserDetails.get(function(response) {
                organization = response.organization;
                user = response.loginAccount;
                userRoles = response.roles;
                OrganizationService.setOrganization(organization);
                $rootScope.user = user;
                $rootScope.user.isLoggedIn = true;
                $rootScope.userId = user.userId;
                deferred.resolve(user);
                self.isLoggedIn = true;
                $rootScope.refreshTranslations();
                $rootScope.$broadcast("userRolesLoaded");
                $rootScope.$broadcast("userLoggedIn");
                CheckNotifications.get({}, function(response) {
                    $rootScope.newNotifications = response.hasNewNotifications;
                });
                },function(errorReason) {
                    user = null;
                    userRoles = null;
                    self.isLoggedIn = false;
                    $rootScope.user = null;
                    deferred.reject(errorReason);
                });
            }

        return deferred.promise;
    };

    this.logout = function(callback) {
        return LogoutResource.save({}, function(response, headers) {
            user = null;
            userRoles = null;
            organization = null;
            self.isLoggedIn = false;
            if (_.isFunction(callback)) {
                callback(response, headers);
            }
        }).$promise;
    };

    this.hasLicensedFeature = function(feature) {
        var deferred = $q.defer();
        OrganizationService.getOrganization().subscribe(function(organization) {
            var hasFeature = $rootScope.hasLicensedFeature(feature, organization);
            if (hasFeature) {
                deferred.resolve(feature);
            } else {
                deferred.reject();
            }
        });
        return deferred.promise;
    };

    this.hasSettingFeature = function(feature) {
        var deferred = $q.defer();
        OrganizationService.getOrganization().subscribe(function(organization) {
            var hasFeature = $rootScope.hasSettingFeature(feature, organization);
            if (hasFeature) {
                deferred.resolve(feature);
            } else {
                deferred.reject();
            }
        });
        return deferred.promise;
    };

    /**
     * Deprecated. Please use 'Auth.hasPermission(Auth.OBJECT_TYPE_UI, rights)' instead!
     */
    this.hasRights = function(rights) {
        return self.hasPermission(self.OBJECT_TYPE_UI, rights);
    };

    this.hasItemPermission = function(action, item) {
        return self.hasPermission(self.OBJECT_TYPE_ITEMS, action, item);
    };

    this.hasChannelPermission = function(action, channel) {
        return self.hasPermission(self.OBJECT_TYPE_COMMUNICATION_CHANNELS, action, channel);
    };

    this.hasSubDestinationPermission = function(action, channel) {
        return self.hasPermission(self.OBJECT_TYPE_EDIT_SUB_DESTINATION, action, channel);
    };

    this.hasContactPermission = function(action, contact) {
        return self.hasPermission(self.OBJECT_TYPE_CONTACT, action, contact);
    };

    this.hasTaskListPermission = function(action, contact) {
        return self.hasPermission(self.OBJECT_TYPE_TASK_LISTS, action, contact);
    };

    this.hasPermission = function(objectType, action, context) {

        if (_.isEmpty(userRoles)) {
            return false;
        }

        for (var i = 0; i < userRoles.length; i++) {
            if (hasRolePermission(userRoles[i], objectType, action, context)) {
                return true;
            }
        }

        return false;
    };

    this.hasDeniedOrganizationRoles = function(organizationRolesDenied) {
        return _.includes(organizationRolesDenied, organization.organizationRole);
    };

    this.hasAnyPermission = function(objectType, actions, context) {
        if (context) {
            context[self.CONTEXT_DEFAULT_VALUE] = self.ANY;
        }
        if (_.isArray(actions)) {
            for (var i = 0; i < actions.length; i++) {
                if (self.hasPermission(objectType, actions[i], context)) {
                    return true;
                }
            }
            return false;
        } else {
            return self.hasPermission(objectType, actions, context);
        }
    };

    function hasRolePermission(role, objectType, action, context) {

        var permission;

        // Find the first matching permission in reverse(!) order
        for (var i = role.permissions.length - 1; i >= 0; i--) {
            permission = role.permissions[i];
            if (matchesPermission(permission, objectType, action, context)) {
                return permission.allowed;
            }
        }

        return false;
    }

    function matchesPermission(permission, objectType, action, context) {

        if (!matchesValue(permission.allowed, permission.objectType, objectType)) {
            return false;
        }

        if (!matchesValue(permission.allowed, permission.actions, action)) {
            return false;
        }

        if (!_.isEmpty(permission.context) && !_.isNil(context)) {
            var value, testValue, defaultValue;
            defaultValue = context[self.CONTEXT_DEFAULT_VALUE];
            for (var key in permission.context) {
                value = permission.context[key];
                testValue = context[key] || defaultValue;
                if (!matchesValue(permission.allowed, value, testValue)) {
                    return false;
                }
            }
        }

        return true;
    }

    function matchesValue(allowed, value, testValue) {

        var matches = false;

        if (_.isNil(value) || value === self.ALL || (allowed && testValue === self.ANY) || _.isEqual(value, testValue)) {
            matches = true;
        } else if (_.isArray(value)) {
            if (_.includes(value, self.ALL)) {
                matches = true;
            } else if (_.isArray(testValue)) {
                matches = (allowed && _.includes(testValue, self.ANY)) || _.intersection(value, testValue).length > 0;
            } else if (_.isObject(testValue)){
                matches = true;
                _.forEach(value, function(property) {
                    for (var key in property) {
                      var partialMatch = _.isMatch(testValue, property) || matchesValue(allowed, property[key], testValue[key]) ;
                      matches = (!matches || !partialMatch)? false: true;
                    }
                });
            } else {
                matches = (allowed && testValue === self.ANY) || _.includes(value, testValue);
            }
        }

        return matches;
    }

})
.factory('EtagLoader', function($log, $q, $window, HttpHeader, HttpStatus) {
    var EtagLoader = function(tag_key, data_key) {

        var self = this;
        var storage = $window.localStorage;

        this.loadDataByResource = function(resource, parameters, loadFunction, restoreFunction, errorFunction) {

            var tag = storage.getItem(tag_key);
            var data = angular.fromJson(storage.getItem(data_key));

            var requestHeader = {};
            if (!_.isEmpty(tag) && !_.isEmpty(data)) {
                requestHeader[HttpHeader.IF_NONE_MATCH] = tag;
            }

            var deferred = $q.defer();

            resource(requestHeader).get(parameters || {},
                function(response, headers) {
                    tag = headers(HttpHeader.ETAG);
                    data = response.toJSON();
                    if (loadFunction) {
                        loadFunction(tag, data, self);
                    }
                    deferred.resolve(tag, data, self);
                },
                function(errorReason) {
                    if (errorReason.status === HttpStatus.NOT_MODIFIED) {
                        if (restoreFunction) {
                            restoreFunction(tag, data, self);
                        }
                        deferred.resolve(tag, data, self);
                    } else {
                        if (errorFunction) {
                            errorFunction(errorReason, self);
                        }
                        deferred.reject(errorReason, self);
                    }
                }
            );

            return deferred.promise;
        };

        this.storeData = function(tag, data) {
            try {
                storage.setItem(tag_key, tag);
                storage.setItem(data_key, angular.toJson(data));
            } catch (e) {
                $log.error("Could not store data! Please clear cache or increase cache size!", e);
                self.removeData();
            }
        };

        this.removeData = function() {
            storage.removeItem(tag_key);
            storage.removeItem(data_key);
        };

    };
    return EtagLoader;
})
.service('OpenETagLoader', function($q, HttpHeader, HttpStatus) {
    var EtagLoader = function() {

        var self = this;

        this.loadDataByResource = function(resource, parameters, getTagFunctionAsync, getDataFunctionAsync, loadFunction, restoreFunction, errorFunction) {
            function fetchData() {
                return Promise.all([
                    getTagFunctionAsync().then(function(tag) {
                        return tag;
                    }),
                    getDataFunctionAsync().then(function(data) {
                        return data;
                    })
                ]).then(function(results) {
                    var tag = results[0];
                    var data = results[1];
                    return { tag: tag, data: data };
                }).catch(function(error) {
                    throw error;
                });
            }

            var requestHeader = {};
            var deferred = $q.defer();

            fetchData().then(function(result) {
                var tag = result.tag;
                var data = result.data;

                if (!_.isEmpty(tag) && !_.isEmpty(data)) {
                    requestHeader[HttpHeader.IF_NONE_MATCH] = tag;
                }

                resource(requestHeader).get(parameters || {},
                    function(response, headers) {
                        tag = headers(HttpHeader.ETAG);
                        data = response.toJSON();
                        if (loadFunction) {
                            loadFunction(tag, data, self);
                        }
                        deferred.resolve(tag, data, self);
                    },
                    function(errorReason) {
                        if (errorReason.status === HttpStatus.NOT_MODIFIED) {
                            if (restoreFunction) {
                                restoreFunction(tag, data, self);
                            }
                            deferred.resolve(tag, data, self);
                        } else {
                            if (errorFunction) {
                                errorFunction(errorReason, self);
                            }
                            deferred.reject(errorReason, self);
                        }
                    }
                );
            });

            return deferred.promise;
        };
    };
    return EtagLoader;
})
.service('UrlRetrievalService', function($http, $q) {

    var cache = {};
    var activeRequests = {};
    var self = this;

    this.clear = function(url) {
        if (!_.isNil(url)) {
            delete cache[url];
        } else {
            cache = {};
        }
    };

    this.get = function(url, forceReload) {

        var deferred = $q.defer();

        var data;
        if (forceReload || !_.has(cache, url)) {

            if (_.has(activeRequests, url)) {
                activeRequests[url].push(deferred);
            } else {

                activeRequests[url] = [deferred];
                $http.get(url).then(function(response) {
                    data = response.data;
                    cache[url] = data;
                    data = _.cloneDeep(data);
                    _.forEach(activeRequests[url], function(activeDeferred) {
                        activeDeferred.resolve(data);
                    });
                    delete activeRequests[url];
                });

            }

        } else {
            data = _.cloneDeep(cache[url]);
            deferred.resolve(data);
        }

        return deferred.promise;
    };

})
.factory('OrganizationService', function($rootScope, OrganizationResource) {
    var organization_ = null;
    var $organization = new rxjs.ReplaySubject({});

    var OrganizationService = {
        $organization: $organization,
        getOrganizationSnapshot: function() {
            return angular.copy(organization_) || {};
        },
        getOrganization: function(force) {
            if (organization_ && !force) {
                $organization.next(angular.copy(organization_));
            } else {
                OrganizationResource.get({},
                    function(response) {
                        organization_ = response;
                        if (organization_.hasImage && _.isNil(organization_.imageUrl)) {
                            organization_.imageUrl = lax_rest_url('organization/image');
                        }
                        $organization.next(angular.copy(organization_));
                    },
                    function(errorResponse) {
                        organization_ = null;
                    });
            }
            return $organization;
        },
        update: function(data) {
            angular.extend(organization_, data);
            $organization.next(organization_);
        },
        save: function(organization, callback) {
            OrganizationResource.save({}, organization, function(response) {
                angular.extend(organization_, response);
                $organization.next(organization_);
                callback(response);
            });
        },
        logout: function() {
            organization_ = null;
            $organization.next(organization_);
        },
        setOrganization: function (organization){
            organization_ = organization;
            $rootScope.organization = organization;
            if (organization_.hasImage && _.isNil(organization_.imageUrl)) {
                organization_.imageUrl = lax_rest_url('organization/image');
            }
            $organization.next(angular.copy(organization_));
        }
    };
    return OrganizationService;
})
.factory('UsersService', function($rootScope, UsersResource) {
    var users_ = null;
    var $users = new rxjs.ReplaySubject({});

    var UsersService = {
        $users: $users,
        getUsersSnapshot: function() {
            return angular.copy(users_);
        },
        reloadAllUsers : function() {
            $users = new rxjs.ReplaySubject({});
            users_ = null;
            this.getUsers(true);
        },
        setActive: function(user, active) {
            if (user) {
                var user_;
                user.active = active;
                user_ = this.getUser(user.userId);
                if (user_) {
                    user_.active = active;
                }
            }
        },
        getUser: function(userId) {
            var predicate;
            if (_.isString(userId)) {
                predicate = {'userId': userId};
            } else if (_.isNumber(userId)) {
                predicate = {'id': userId};
            } else {
                return userId;
            }
            return _.find(users_, predicate);
        },
        getUsers: function(force) {
            if (users_ && !force) {
                $users.next(angular.copy(users_));
            } else if (users_ && users_.$promise) {
                users_.$promise.then(function(response) {
                    responseCbk(response);
                });
            } else {
                users_ = UsersResource.query({},
                    function(response) {
                        responseCbk(response);
                    },
                    function(errorResponse) {
                        users_ = null;
                    });
            }
            function responseCbk(response) {
                users_ = _(response)
                    .map(function(user) {
                        user.displayName = $rootScope.getUserSalutation(user);
                        if (_.isEqual(user.userId, $rootScope.user.userId)) {
                            user.owner = true;
                        }
                        return user;
                    })
                    .sortBy(['owner', 'lastName'])
                    .value();
                $users.next(angular.copy(users_));
            }
            return $users;
        },
        updateUser: function(data) {
            angular.extend(this.getUser(data.userId), data);
            $users.next(angular.copy(users_));
        }
    };
    return UsersService;
})
.factory('CurrencyService', function($rootScope) {
    return {
        getCurrencyKey: function(country) {
            var countryToCurrencyMap = {
                "AT": "EUR",
                "BE": "EUR",
                "CY": "EUR",
                "DE": "EUR",
                "EE": "EUR",
                "ES": "EUR",
                "FI": "EUR",
                "FR": "EUR",
                "GB": "GBP",
                "GR": "EUR",
                "IE": "EUR",
                "IT": "EUR",
                "LT": "EUR",
                "LU": "EUR",
                "LV": "EUR",
                "MT": "EUR",
                "NL": "EUR",
                "PT": "EUR",
                "SI": "EUR",
                "SK": "EUR",
                "UK": "GBP",
                "US": "USD"
            };

            var currency = countryToCurrencyMap[country];
            if (!currency) {
                currency = $rootScope.systemSettings.SUPPORTED_CURRENCIES[0] || 'USD';
            }

            return currency;
        },
        getCurrencySymbol: function(currencyKey) {
            var currencySymbols = {
                "GBP": "£",
                "EUR": "€",
                "USD": "$"
            };

            return currencySymbols[currencyKey];
        }
    };
})
.factory('InfiniteScrollResource', function($resource, HttpHeader) {
    var InfiniteScrollResource = function(url, cursorName, paramDefaults, actionDefaults) {

        var self = this;

        this.cursorName = cursorName || HttpHeader.CURSOR;
        this.paramDefaults = paramDefaults || {};
        this.actionDefaults = actionDefaults || {};

        // Always add a 'query' action, if not already done
        this.actionDefaults.query = this.actionDefaults.query || {
            method: 'GET',
            isArray: true
        };

        this.createCursorAwareResource = function(cursor) {

            // Set 'cursor' header in all actions
            var actions = _.cloneDeep(self.actionDefaults);
            if (cursor) {
                _.forEach(actions, function(action) {
                    action.headers = action.headers || {};
                    action.headers[self.cursorName] = cursor;
                });
            }

            return $resource(lax_rest_url_escaped(url), self.paramDefaults, actions);
        };

    };
    return InfiniteScrollResource;
})
.factory('InfiniteScrollHelper', function($rootScope, $log, $timeout) {
    var InfiniteScrollHelper = function(loaderResource, scope, dataVar, gridApiVar, errorHandler) {

        var self = this;

        this.isLoading = false;
        this.isLoadComplete = false;
        this.cursor = null;
        this.queryParams = {};

        this.loaderResource = loaderResource;
        this.scope = scope;

        this.dataVar = dataVar || 'data';
        this.gridApiVar = gridApiVar || 'gridApi';
        this.errorHandler = errorHandler || 'errorStatus';

        function get(scope, varName) {
            if (_.isFunction(varName)) {
                return varName();
            } else if (_.isFunction(scope[varName])) {
                return scope[varName]();
            } else {
                return scope[varName];
            }
        }

        // FIXME: Stupid IE does not support 'rest' or 'spread' arguments,
        // so we have to use this ugly two definition...
        function set(scope, varName, value1, value2) {
            if (_.isFunction(varName)) {
                return varName(value1, value2);
            } else if (_.isFunction(scope[varName])) {
                return scope[varName](value1, value2);
            } else {
                scope[varName] = value1;
                return scope[varName];
            }
        }

        this.getData = function() {
            return get(self.scope, self.dataVar);
        };

        this.setData = function(data) {
            set(self.scope, self.dataVar, data);
        };

        this.getGridApi = function() {
            return get(self.scope, self.gridApiVar);
        };

        this.setGridApi = function(gridApi) {
            set(self.scope, self.gridApiVar, gridApi);
        };

        this.callErrorHandler = function(errorStatus, errorData) {
            set(self.scope, self.errorHandler, errorStatus, errorData);
        };

        this.addToGridOptions = function(gridOptions, onRegisterApiCbk) {

            gridOptions.infiniteScrollUp = false;
            gridOptions.infiniteScrollDown = true;
            gridOptions.onRegisterApi = function(gridApi) {
                if (!gridApi.infiniteScroll) {
                    $log.warn("'gridApi.infiniteScroll' does not exist. Did you forget to add the 'ui-grid-infinite-scroll' tag to the HTML element?");
                } else {
                    gridApi.infiniteScroll.on.needLoadMoreData(self.scope, self.loadMoreData);
                    self.setGridApi(gridApi);
                    $timeout(function() {
                        gridApi.core.handleWindowResize();
                    });
                }
                if (onRegisterApiCbk) {
                    onRegisterApiCbk(gridApi);
                }
            };

        };

        this.loadMoreData = function() {
            if (!self.isLoading && !self.isLoadComplete) {
                config = {
                    append: true,
                    queryParams: self.queryParams
                };
                $rootScope.$broadcast('infiniteScrollLoadMore');
                self.load(config);
            }
        };

        this.load = function(config) {
            config = config || {};
            var queryParams = config.queryParams || {};
            self.queryParams = queryParams;

            if (!config.append) {
                // Turn off scrolling on initial load
                // FIXME: Is this really necessary?
                var gridApi = self.getGridApi();
                if (gridApi && gridApi.infiniteScroll) {
                    gridApi.infiniteScroll.setScrollDirections(false, false);
                }
                self.cursor = null;
            }

            self.isLoading = true;

            return self.loaderResource
                .createCursorAwareResource(self.cursor)
                .query(queryParams, function(response, headers) {
                    self.handleResponse(response, headers, config);
                }, function(errorResponse) {
                    self.handleError(errorResponse);
                });
        };

        this.reload = function(queryParams) {
            self.isLoading = false;
            self.isLoadComplete = false;
            self.cursor = null;
            self.queryParams = queryParams;

            return self.load({ append: false, queryParams: queryParams });
        };

        this.setDataLoaded = function() {
            var gridApi = self.getGridApi();
            if (gridApi && gridApi.infiniteScroll) {
                gridApi.infiniteScroll.dataLoaded(false, true);
            }
            self.isLoading = false;
            $rootScope.$broadcast('infiniteScrollDataLoaded');
        };

        this.handleResponse = function(response, headers, config) {

            var cursor = headers(self.loaderResource.cursorName);
            if (response.length === 0) {
                self.isLoadComplete = true;
            } else {
                if (cursor && cursor != self.cursor) {
                    self.cursor = cursor;
                } else {
                    self.cursor = null;
                    self.isLoadComplete = true;
                }
            }

            if (config.append) {
                self.setData(_.concat(self.getData(), response));
            } else {
                self.setData(response);
            }

            self.setDataLoaded();

        };

        this.handleError = function(errorResponse) {
            self.setDataLoaded();
            self.callErrorHandler(errorResponse.status, errorResponse.data);
        };

    };
    return InfiniteScrollHelper;
})
.factory('UserImageResource', function ($resource) {
    return $resource(lax_rest_url_escaped('user/image'), {}, {
        get: {method: 'GET'},
        save: {method: 'POST',
            isArray: false,
            headers: {'Content-Type': 'multipart/form-data'}
        }
    });
})
.factory('UserResource', function ($resource) {
    var url = lax_rest_url_escaped('user');
    return $resource(url, {}, {
        get: {method: 'GET'},
        filters: {method: 'GET', url: url + '/filters', isArray: true},
        roles: {method: 'GET', url: url + '/roles', isArray: true},
        save: {method: 'POST'},
        changePassword: { method: 'POST', url: url + '/password' },
        updateNotificationsLastRead : { method: 'PUT', url: url + '/notificationsLastRead/:lastRead', params: {lastRead:'@lastRead'} },
        getAccountOrganizations: { method: 'GET', url: url + '/accountOrganizations' },
        deleteAccount: { method: 'DELETE', url: url + '/account/:organizationId' },
        switchOrganization: { method: 'PUT', url: url + '/currentOrganization?recreateCookie=true' },
        existsUser: {
            method: 'GET',
            url: lax_rest_url_escaped('signup') + '/existsUser/:emailOrUsername?invitationReference=:invitationReference',
            params: {
                emailOrUsername: '@emailOrUsername',
                invitationReference: '@invitationReference'
            }
        }
    });
})
.factory('UserDetails', function ($resource) {
    return $resource(lax_rest_url_escaped('userDetails'), {}, {
        get: {method: 'GET'}
    });
})
.factory('UserPreferencesResource', function ($resource) {
    var url = lax_rest_url_escaped('user/preferences');
    return $resource(url, {}, {
        get: {method: 'GET'},
        update: {method: 'PUT', url: url + '/:key', params: {key: '@key'}},
        delete: {method: 'DELETE', url: url + '/:key', params: {key: '@key'}},
        save: {method: 'POST'}
    });
})
.factory('UsersGroupResource', function ($resource) {
    var url = lax_rest_url_escaped('userGroups');
    return $resource(url, {}, {
        getAll: {method: 'GET', isArray: true},
        update: {method: 'PUT'},
        save: {method: 'POST'},
        delete: {method: 'DELETE', url: url + '/:id',params: {id: '@id'}}
    });
})
.service('UsersGroupService', function($rootScope, $q, $cacheFactory, UsersGroupResource, UsersService) {
    var userGroupsFactory = $cacheFactory('user-groups');
    var groups_ = null;
    var this_ = this;

    this.reloadGroups = function() {
        userGroupsFactory.remove('all-user-groups');
        groups_ = null;
        this.getAll().then(function(groups) {
            groups_ = groups;
        });
    };

    this.reloadUsersAndGroups = function() {
        this.reloadGroups();
        UsersService.reloadAllUsers();
    };

    this.getAll = function() {
        return this.loadAllUserGroups('all-user-groups');
    };

    this.create = function(group) {
        return UsersGroupResource.save(group).$promise.then(function(response) {
            return response;
        });
    };

    this.update = function(group) {
        return UsersGroupResource.update(group).$promise.then(function(response) {
            return response;
        });
    };

    this.delete = function(group) {
        return UsersGroupResource.delete(group).$promise.then(function(response) {
            return response;
        });
    };

    this.loadAllUserGroups = function(key) {
        if (_.isEmpty(key)) {
            return $q.when({});
        }
        if (groups_) {
            return $q.when(groups_);
        }

        var userGroupsData = userGroupsFactory.get(key);

        if (_.isNil(userGroupsData)) {

            var query = UsersGroupResource.getAll({});

            var promise = query.$promise.then(function(response) {
                groups_ = response;
                userGroupsFactory.put(key, response);
                return response;
            }, function(errorReason) {
                // FIXME: Depending on the error we might want to retry the query
                return {};
            });

            // Cache the promise to reduce network traffic
            if (!query.$resolved) {
                userGroupsData = {
                    promise: promise
                };
                userGroupsFactory.put(key, userGroupsData);
            }

            return promise;
        }

        if (userGroupsData.promise) {
            return userGroupsData.promise;
        } else {
            groups_ = userGroupsData;
            return $q.when(userGroupsData);
        }
    };
})
.factory('CheckNotifications', function($resource) {
    return $resource(lax_rest_url("hasNewNotification"), {});
})
.factory('UserDataModelResource', function ($resource) {
    return function(customHeaders) {
        return $resource(lax_rest_url_escaped('user/datamodel'), {}, {
            get: {method: 'GET', isArray: false, headers: customHeaders || {}}
        });
    };
})
.factory('UsersResource', function($resource) {
    var url = lax_rest_url_escaped('users/:accountId');
    return $resource(url, {accountId: '@accountId'}, {
        setActive: {
            method: 'PUT',
            url: url + '/active/:active',
            params: {active: '@active'}
        },
        updateUser: {
            method: 'PUT',
            transformRequest: function(data) {
                return angular.toJson(data.payload);
            },
            isArray: false
        }
    });
})
.factory('LogoutResource', function ($resource) {
    return $resource(lax_rest_url_escaped('logout'), {}, {});
})
.factory('LoginResource', function ($resource) {
    return $resource(lax_rest_url_escaped('login'), {}, {});
})
.factory('SignupResource', function ($resource) {
    return $resource(lax_rest_url_escaped('signup'), {}, {});
})
.factory('ResetPasswordResource', function ($resource) {
    return $resource(lax_rest_url_escaped('resetpassword'), {}, {});
})
.factory('ResendMailResource', function ($resource) {
    return $resource(lax_rest_url_escaped('signup/resendconfirmation/:email'), {email:'@email'}, {});
})
.factory('ItemResource', function ($resource) {
    var url = lax_rest_url_escaped('items') + '/:primaryKey';
    return $resource(url, {primaryKey: '@primaryKey', publicationDestination: '@publicationDestination', status: '@status'}, {
        get: {method: 'GET', isArray: false},
        query: {method: 'GET', isArray: true},
        copy: {method: 'GET', url: url + '/copy', params: {primaryKey: '@primaryKey'}, isArray: false},
        save: {method: 'POST'},
        audit: {method: 'POST', url: url + '/audit', params: {primaryKey: '@primaryKey', status: '@status'}},
        reindex: {method: 'POST', url: url + '/searchindex/recalculate'},
        getPublications: {method: 'GET', url: url + '/publications', isArray: true},
        getValidationResult: {method: 'GET', url: url + '/validationResult', isArray: false},
    });
})
.factory('ExportItemResource', function($resource) {
    return $resource(lax_rest_url_escaped('items/exports/:selectionId'), {
        selectionId: '@selectionId',
        type: '@type',
        language: '@language'
    }, {
        delete: {method: 'DELETE', params: {before: '@before'}, isArray: true},
    });
})
.factory('ValidateItemsResource', function($resource) {
    var url = lax_rest_url_escaped('items/validate');
    return $resource(url, {}, {
        validate: {method: 'POST' },
    });
})
.factory('MassUpdateResource', function($resource) {
    return $resource(lax_rest_url_escaped('items/massupdate'), {}, {
        save: {method: 'POST'}
    });
})
.factory('ItemsSearchFilterResource', function($resource) {
    return $resource(lax_rest_url_escaped('filterProfiles/:identifier'), {identifier: '@identifier'}, {});
})
.factory('QueryItemResource', function(HttpHeader, InfiniteScrollResource) {
    return new InfiniteScrollResource('items', HttpHeader.ITEM_CURSOR, null, {
        search: {
            method: 'POST',
            isArray: true,
            url: lax_rest_url_escaped('items/search')
        }
    });
})
.factory('DeleteItemsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('deleteItems'), {}, {
        pass: {method: 'POST', isArray: true}
    });
})
.factory('ExportUsersResource', function ($resource) {
    return $resource(lax_rest_url_escaped('exportusers'), {}, {
      get: {
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function (data, headersGetter) {

          var headers = headersGetter();
          var filename = headers['content-disposition'].split('=')[1];
          var blob = new Blob([data], { type: 'application/vnd.ms-excel' });

          return {
            blob: blob,
            filename: filename
          };

        }
      }
    });
})
.factory('CurrentOperationsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('currentOperations/:operationKey'), {}, {});
})
.factory('FindItemsByPrimaryKeyResource', function ($resource) {
    return $resource(lax_rest_url_escaped('findByPrimaryKey'), {}, {
        find: {method: 'POST', isArray:true }
    });
})
.factory('FeedbackResource', function ($resource) {
    return $resource(lax_rest_url_escaped('items')+'/:primaryKey/feedback', {primaryKey: '@primaryKey'}, {});
})
.factory('ItemHistoryResource', function ($resource) {
    return $resource(lax_rest_url_escaped('items')+'/:primaryKey/history', {primaryKey: '@primaryKey'}, {
        get: {method: 'GET', isArray: false}
    });
})
.factory('ItemEventHistoryResource', function ($resource) {
    return $resource(lax_rest_url_escaped('items')+'/:primaryKey/eventHistory', {primaryKey: '@primaryKey'});
})
.factory('PublicationResource', function ($resource) {
    var url = lax_rest_url_escaped('publications');
    return $resource(url, {}, {
        publish: {method: 'POST'},
        stopPublication: {method: 'POST', url: url + '/abort/:taskId', params: {taskId: '@taskId'}},
        getResponseErrors: {method: 'GET', url: url + '/:taskId/messagingResponseErrors', params: {taskId: '@taskId'}, isArray: true}
    });
})
.factory('QueryPublicationResource', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('publications');
})
.factory('DePublicationResource', function ($resource) {
    return $resource(lax_rest_url_escaped('publications/delete'), {}, {
        depublish: {method: 'POST'}
    });
})
.factory('RepublishResource', function ($resource) {
    return $resource(lax_rest_url_escaped('publications/republish/:taskId'), {taskId:'@taskId'}, {
        publish: {method: 'POST'}
    });
})
.factory('JobResource', function ($resource) {
    var url = lax_rest_url_escaped('jobs') + '/:jobId';
    return $resource(url, {jobId: '@jobId'}, {
        get: {method: 'GET', isArray: false},
        query: {method: 'GET', isArray: true},
        save: {method: 'POST'},
        delete: {method: 'DELETE', params: {jobId: '@jobId'}},
        run: {method: 'POST', url: url + '/run', params: {jobId: '@jobId'}}
    });
})
.factory('BackendValidateItemResource', function ($resource) {
    var url = lax_base_url_escaped('/validation/v1/validateItem');
    return $resource(url, {}, {
        validate: {method: 'POST'}
    });
})
.factory('ValidationResource', function ($resource) {
    var url = lax_rest_url_escaped('validate');
    return $resource(url, {}, {
        validate: {method: 'POST'}
    });
})
.factory('RevalidateItemsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('revalidateItems'), {}, {});
})
.factory('RecalculateStatisticsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('recalculateStatistics'), {}, {});
})
.factory('CategoryResource', function ($resource) {
    return $resource(lax_rest_url_escaped('categories')+'/:category', {category: '@category'}, {});
})
.factory('StatusResource', function ($resource) {
    return $resource(lax_rest_url_escaped('status'), {}, {});
})
.factory('LayoutResource', function ($resource) {
    return $resource(lax_rest_url_escaped('layouts')+"/:layout", {layout:'@layout'}, {});
})
.factory('MonitoringResource', function($resource) {
    return $resource(lax_rest_url_escaped('monitorings'), {
        module: '@module',
        term: '@term',
        datefilter: '@datefilter'
    }, {});
})
.factory('QueryMonitoringResource', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('monitorings');
})
.factory('DateFilterResource', function ($resource) {
    return $resource(lax_rest_url_escaped('datefilters'), {}, {});
})
.factory('ModuleResource', function($resource) {
    return $resource(lax_rest_url_escaped('modules'), {}, {});
})
.factory('ConfirmResource', function($resource) {
    return $resource(lax_rest_url_escaped('signup/confirm/:key/:opaque'), {
        key: '@key',
        opaque: '@opaque'
    }, {});
})
.factory('ImportResource', function($resource) {
    return $resource(lax_rest_url_escaped('imports/:id'), {id: '@id'}, {});
})
.factory('QueryImportResource', function(HttpHeader, InfiniteScrollResource) {
    return new InfiniteScrollResource('imports', HttpHeader.MESSAGE_CURSOR);
})
.factory('DashboardResource', function ($resource) {
    var url = lax_rest_url_escaped('user/dashboard');
    return $resource(url, {}, {
        get: {
            method: 'GET',
            isArray: true, // Set isArray to false to expect an object response
        },
        save: { method: 'POST' },
    });
})
.service('DashboardService', function($window, $rootScope) {

    var dashboardSettings = {};
    var DASHBOARD_SETTINGS = '-dashboardSettings';

    this.loadSettings = function(options,callback) {
        // Use options to get settings if require

        // Get data from local storage
        var data = $window.localStorage.getItem($rootScope.user.userId + DASHBOARD_SETTINGS);
        if (data) {
            dashboardSettings = JSON.parse(data);
            callback(dashboardSettings);
        } else {
            settings = {};
            callback({});
        }
    };

    this.getSettings = function() {
        return dashboardSettings;
    };

    this.saveSettings = function(settings) {
        dashboardSettings = settings || {};
        var key = $rootScope.user.userId + DASHBOARD_SETTINGS;
        if (_.isEmpty(dashboardSettings)) {
            $window.localStorage.removeItem(key);
        } else {
            $window.localStorage.setItem(key, JSON.stringify(dashboardSettings));
        }
    };

    this.getDimensionValue = function(attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        var dimension;
        if (!_.isEmpty(attributes)) {

            var dashboardSettings = this.getSettings();

            dimension = "";
            _.each(attributes, function(attribute) {
                var value = dashboardSettings[attribute];
                if (!_.isEmpty(value)) {
                    if (!_.isEmpty(dimension)) {
                        dimension += ":";
                    }
                    dimension += value;
                }
            });

        } else {
            dimension = null;
        }

        return dimension;
    };

    this.getBrowseLocation = function(location, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        location = location || {};
        if (!_.isEmpty(attributes)) {

            var dashboardSettings = this.getSettings();

            location.q = location.q || '';
            _.each(attributes, function(attribute) {
                var value = dashboardSettings[attribute];
                if (!_.isEmpty(value)) {
                    if (!_.isEmpty(location.q)) {
                        location.q += " AND ";
                    }
                    location.q += attribute + " = " + value;
                }
            });

        }

        return location;
    };

    this.getBrowseLink = function(location, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        location = this.getBrowseLocation(location, attributes);

        var link = '/browse';
        if (!_.isEmpty(location)) {
            var c = '?';
            _.each(location, function(value, key) {
                link += c + _.escape(key) + "=" + _.escape(value);
                c = '&';
            });
        }

        return link;
    };
})
.factory('UsageLimitsResource', function($resource) {
    return $resource(lax_rest_url_escaped('usagelimits'), {}, {});
})
.factory('OrganizationResource', function($resource) {
    return $resource(lax_rest_url_escaped('organization'), {}, {
        existsOrganization: {
            method: 'GET',
            url: lax_rest_url_escaped('signup') + '/existsOrganization?invitationReference=:invitationReference',
            params: {
                invitationReference: '@invitationReference'
            }
        }
    });
})
.factory('OrganizationsResource', function($resource) {
    return $resource(lax_rest_url_escaped('organizations'), {keyword:'@keyword', communityId: '@communityId'});
})
.factory('SubscriptionResource', function($resource) {
    return $resource(lax_rest_url_escaped('subscriptions'), {channelId: '@channelId'}, {
        query: { method: 'GET', isArray: true }
    });
})
.factory('StatisticsResource', function($resource) {
    return $resource(lax_rest_url_escaped('statistics/dynamic/:name'), {
        countMax: '@countMax',
        countMin: '@countMin',
        dimension: '@dimension',
        keyMax: '@keyMax',
        keyMin: '@keyMin',
        limit: '@limit',
        name: '@name',
        sortBy: '@sortBy'
    }, {});
})
.factory('InvitationResource', function($resource) {
    return $resource(lax_rest_url_escaped('contacts/:organizationId/:action'), {
        organizationId: '@organizationId',
        action: '@action'
    }, {});
})
.factory('ContactsResource', function($resource) {
    var url = lax_rest_url_escaped('contacts');
    return $resource(url, {}, {
        save: {
            method: 'PUT'
        },
        create : {
            method: 'POST'
        },
        delete : {
            method: 'DELETE',
            url: url + '/:organizationId',
            params: {
                organizationId: '@organizationId'
            }
        },
        invite: {
            method: 'POST',
            params: {
                organizationId: null
            }
        }
    });
})
.factory('ContactsResourceByCursor', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('contacts/search', 'x-cursor',  {limit: '@limit', keywords:'@keywords', sortby:'@sortby'}, null);
})
.factory('InviteByEmailResource', function($resource) {
    return $resource(lax_rest_url_escaped('invitations/:invitationId'), {
        invitationId: '@invitationId'
    }, {});
})
.factory('TranslationResource', function($resource) {
    return function(customHeaders) {
        return $resource(lax_rest_url_escaped('translations/:languageKey'), {
            languageKey: '@languageKey'
        }, {
            get: {method: 'GET', isArray: false, headers: customHeaders || {}}
        });
    };
})
.factory('DataModelResource', function($resource) {
    return function(customHeaders) {
        var url = lax_rest_url_escaped('datamodel');
        return $resource(url, {}, {
            get: {
                method: 'GET',
                isArray: false,
                url: url + '/current',
                headers: customHeaders || {}
            },
            getCategoryPath: {
                method: 'GET',
                url: url + '/categoryPath/:code',
                isArray: true
            },
            getOptionListOptions: {
                method: 'GET',
                url: url + '/optionLists/:optionListName/options',
                isArray: true
            },
            getOptionListGroups: {
                method: 'GET',
                url: url + '/optionLists/:optionListName/groups',
                isArray: true
            }
        });
    };
})
.factory('ResetDataModelResource', function ($resource) {
    return $resource(lax_rest_url_escaped('datamodelManager/reset'), {}, {});
})
.factory('UploadCustomDataModelResource', function ($resource) {
    return $resource(lax_rest_url_escaped('datamodelManager/customization'), {}, {
        get: {method: 'GET', isArray: false},
        save: {method: 'POST'}
    });
})
.factory('SentRequestResource', function ($resource) {
    return $resource(lax_rest_url_escaped('requestforinformation/sent/:requestId'), {requestId: '@requestId'}, {});
})
.factory('UseDatamodelResource', function ($resource) {
    return $resource(lax_rest_url_escaped('models/:modelId'), {modelId: '@modelId', isSystemDataModel: '@isSystemDataModel'}, {});
})
.factory('UploadMappingResource', function ($resource) {
    return $resource(lax_rest_url_escaped('uploads/mapping'), {}, {});
})
.factory('RunMappingResource', function ($resource) {
    return $resource(lax_rest_url_escaped('uploads/mapping/run'), {}, {});
})
.factory('ReceivedRequestResource', function ($resource) {
    return $resource(lax_rest_url_escaped('requestforinformation/received/:requestId'), {requestId: '@requestId'}, {});
})
.factory('DownloadRequestResource', function ($resource) {
    return $resource(lax_rest_url_escaped('requestforinformation/download/:requestId'), {requestId: '@requestId'}, {});
})
.factory('ConfirmResetPasswordResource', function ($resource) {
    return $resource(lax_rest_url_escaped('resetpassword/confirm/:key/:opaque'), {key: '@key', opaque: '@opaque'}, {});
})
.factory('FavoritesResource', function ($resource) {
    return $resource(lax_rest_url_escaped('items/favorites') +'/:primaryKey', {primaryKey: '@primaryKey'}, {
        removeFromFavorite: {method: 'DELETE'},
        addToFavorite: {method: 'POST'}
    });
})
.factory('ReviewerReviewResource', function($resource) {
    return $resource(lax_rest_url_escaped('reviewerreviews') + '/:primaryKey', {primaryKey: '@primaryKey'}, {
        'save': {method: 'POST', params: {primaryKey: null}},
        'delete': {method: 'DELETE'},
        'get': {method: 'GET'}
    });
})
.factory('SupplierReviewResource', function($resource) {
    return $resource(lax_rest_url_escaped('supplierreviews') + '/:primaryKey', {primaryKey: '@primaryKey'}, {
        'get': {method: 'GET', isArray: true}
    });
})
.factory('SingleSignOnResource', function($resource) {
    return $resource(lax_rest_url_escaped('sso/status') , {}, {
        'get': {method: 'GET'}
    });
})
.factory('CommunicationChannelTypesResource', function($resource) {
    return $resource(lax_rest_url("communicationchanneltypes"), {});
})
.factory('CommunicationChannelSubDestinationsAndBlockedReviewersResource', function ($resource) {
    var url = lax_rest_url_escaped('communicationchannels/subdestinations_and_blockedreviewers');
    return $resource(url, {}, {
        'save': {method: 'POST'}
    });
})
.factory('CommunicationChannelResource', function ($resource) {
    var url = lax_rest_url_escaped('communicationchannels') + '/:channelId';
    return $resource(url, {}, {
        'activate': {method: 'PUT',
                     params: {force: true},
                     url: url + '/activation',
                     isArray: true},
        'deactivate': {method: 'DELETE',
                       url: url + '/activation'},
        'resetcountkeydate':{method: 'PUT',
                          url: url + '/resetcountkeydate'}
    });
})
.value("PublicationMode", {
    PUBLISH: "PUBLISH",
    DEPUBLISH: "DEPUBLISH"
})
.service('CommunicationChannelService', function($q, $rootScope, CommunicationChannelResource, CommunicationChannelTypesResource, ContactsResource, PublicationMode) {

    function isPlanSupported(plan, channel, mode) {
        if (!plan) {
            return false;
        }
        var actions = plan.planActions || {};
        var channelTypes = actions[(mode === PublicationMode.PUBLISH ? 'publish_action' : 'unpublish_action')] || [];
        return _.includes(channelTypes, channel.type);
    }

    this.loadPublicationDestinations = function(config) {

        var deferred = $q.defer();

        $q.all([
            ContactsResource.query({}).$promise,
            CommunicationChannelResource.query({}).$promise,
            CommunicationChannelTypesResource.get({}).$promise
        ]).then(function(results) {

            var contactResult = results[0];
            var channelResult = results[1];
            var channelsVerificationResult = results[2];

            var organizations = _.sortBy(_.filter(contactResult, function(contact) {
                return !_.isNil(contact.organizationId) &&
                    contact.state === 'ESTABLISHED' &&
                    (_.isNil(contact.contactRole) || contact.contactRole !== 'DATA_SUPPLIER');
            }), function(contact) {
                return contact && contact.name ? contact.name.toLowerCase() : -1;
            });

            var communicationChannels = [];
            _.forEach(channelResult, function(channel) {

                if (!channel.active ||
                    !channelsVerificationResult[channel.type] ||
                    channel.direction === 'INBOUND_ONLY') {
                    return;
                }

                var plan;
                if (channel.plan) {
                    plan = $rootScope.communicationPlans[channel.plan];
                    if (!_.isEmpty(config) && !isPlanSupported(plan, channel, config.publicationMode)) {
                        return;
                    }
                    channel.planLabel = plan.label || plan.name;
                } else if (channel.format) {
                    channel.planLabel = $rootScope.translateValue('COMMUNICATION_CHANNELS.FORMATS.',
                        channel.format);
                }

                communicationChannels.push(channel);
                if (plan && plan.supportsSubDestinations && !_.isEmpty(channel.subDestinations)) {
                    for (var subDestinationKey in channel.subDestinations) {
                        var subChannel = angular.copy(channel);
                        subChannel.subDestinationKey = subDestinationKey;
                        subChannel.subDestinationLabel = channel.subDestinations[subDestinationKey];
                        delete subChannel.subDestinations;
                        communicationChannels.push(subChannel);
                    }
                }

            });

            communicationChannels = _.sortBy(communicationChannels, function(channel) {
                var key = channel.name;
                if (channel.subDestinationLabel) {
                    key += channel.subDestinationLabel;
                }
                if (channel.subDestinationKey) {
                    key += channel.subDestinationKey;
                }
                return key.toLowerCase();
            });

            deferred.resolve({
                organizations: organizations,
                communicationChannels: communicationChannels
            });

        }).catch(function(err) {
            deferred.reject(err.status);
        });

        return deferred.promise;
    };

})
.factory('CommunicationPlanResource', function($resource) {
    var url = lax_rest_url_escaped('communicationplans') + '/:planName';
    return $resource(url, {}, {
        'channeltypes': {
            method: 'GET',
            url: url + '/channeltypes',
            isArray: true
        }
    });
})
.factory('CommunityResource', function($resource) {
    var url = lax_rest_url_escaped('communities') + '/:id';
    return $resource(url, {}, {});
})
.factory('CommunicationChannelTemplatesResource', function($resource) {
    return $resource(lax_rest_url_escaped('communicationchannelTemplates'), {}, {});
})
.factory('BillingResource', function($resource) {
    var url = lax_rest_url_escaped('billing');
    return $resource(url, {}, {
        changePlan: {
            method: 'POST',
            url: url + '/changePlan'
        },
        addAdditionalLicense: {
            method: 'POST',
            url: url + '/addAdditionalLicense'
        },
        getBillingAddress: {
            method: 'GET',
            url: url + '/billingAddress',
            isArray: false
        },
        getPlanBillingProfiles: {
            method: 'GET',
            url: url + '/planProfiles',
            isArray: true
        },
        getAdditionalLicenseProfiles: {
            method: 'GET',
            url: url + '/additionalLicenseProfiles',
            isArray: true
        },
        getOrderedPlanProfile: {
            method: 'GET',
            url: url + '/orderedPlanProfile',
            isArray: false
        },
        getOrderedAdditionalLicenseProfiles: {
            method: 'GET',
            url: url + '/orderedAdditionalLicenseProfiles',
            isArray: true
        }
    });
})
.factory('ActivityStreamResource', function($resource) {
    return $resource(lax_rest_url_escaped('activitystreamtime') + '/:userId', {userId: '@userId'}, {
        'save': {method: 'POST', params: {time: null}},
        'get': {method: 'GET'}
    });
})
.factory('RubyScriptService', function($http) {
    var rubyService = {
        executeScript: function(script) {

            var promise = $http({
                method: 'POST',
                url: lax_rest_url('execute/ruby'),
                data: script,
                dataType: 'text',
                headers: {'Content-Type': 'text/plain', 'Accept': 'text/plain'},
                transformResponse: function(data, headers) {
                    return data;
                }
            }).then(function(response) {
                return response.data;
            });

            return promise;
        }
    };
    return rubyService;
})
.factory('TaskResource', function($resource) {
    var url = lax_rest_url_escaped('taskLists/tasks/:taskId');
    return $resource(url, {
        taskId: '@taskId'
    }, {
        saveComment: {
            method: 'POST',
            url: url + '/comments'
        },
        getComments: {
            method: 'GET',
            url: url + '/comments/:commentId',
            params: {
                commentId: '@commentId'
            },
            isArray: true
        },
        getStatus: {
            method: 'GET',
            url: url + '/status'
        },
        setStatus: {
            method: 'POST',
            url: url + '/status/:status',
            params: {
                status: '@status'
            }
        }
    });
})
.factory('QueryTasksResource', function($resource, HttpHeader) {
    return {
        createCursorAwareResource: function(taskListId, cursor) {
            var headers = {};
            if (cursor) {
                headers[HttpHeader.MESSAGE_CURSOR] = cursor;
            }
            return $resource(lax_rest_url_escaped('taskLists/' + taskListId), {queryParams: '@queryParams'}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    headers: headers
                }
            });
        }
    };
})
.factory('TaskListsResource', function($resource) {
    return $resource(lax_rest_url_escaped('taskLists/:taskListId'), {
        taskListId: '@taskListId'
    }, {});
})
.factory('MessagingResource', function ($resource) {
    return $resource(lax_rest_url_escaped('messaging/messagingassets'), {channelId: '@channelId'}, {});
})
.factory('QueryMessageResource', function(HttpHeader, InfiniteScrollResource) {
    return new InfiniteScrollResource('messaging/messagingassets', HttpHeader.MESSAGE_CURSOR);
})
.factory('NewsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('news'), {}, {});
})
.factory('SelectionResource', function ($resource) {
    var url = lax_rest_url_escaped('selections/:selectionId');
    return $resource(url, {selectionId: '@selectionId'}, {});
})
.factory('ExportFormatsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('datamodel/exportFormats'), { 'includeExportMappings': false }, {});
})
.factory('ExportMappingResource', function ($resource) {
    var baseUrl = lax_rest_url_escaped('export/mappings');
    return $resource(baseUrl, {}, {
        'getActive': {
            method: 'GET',
            url: baseUrl + '/active',
            interceptor: {
                response: function(response) {
                    return response;
                }
            }
        },
        'activate': {
            method: 'POST',
            url: baseUrl + '/activate'
        },
        'delete': {
            method: 'DELETE',
            url: baseUrl + '/:mappingName',
            params: {mappingName: '@mappingName'}
        },
        'query': {
            method: 'GET',
            isArray: true,
            interceptor: {
                response: function(response) {
                    return response;
                }
            }
        },
        'getTemplateMappings': {
            method: 'GET',
            url: baseUrl + '?templateMappings=true',
            isArray: true,
            interceptor: {
                response: function(response) {
                    return response;
                }
            }
        },
        'get': {
            method: 'GET',
            url: baseUrl + '/:key',
            interceptor: {
                response: function(response) {
                    return response;
                }
            }
        }
    });
})
.factory('ExportSubscriptionsResource', function ($resource) {
    return $resource(lax_rest_url_escaped('exportsubscriptions'), {}, {});
})
.factory('ExportSummaryResource', function (HttpHeader, InfiniteScrollResource) {
    return new InfiniteScrollResource('items/exports', HttpHeader.MESSAGE_CURSOR, {type: '@type', status: '@status'});
})
.factory('DataModelTemplateResource', function ($resource) {
    var baseUrl = lax_rest_url_escaped('datamodelTemplate');
    var url = baseUrl + "/:id";
    return $resource(url, {id: '@id'}, {
        'getOwnTemplates': {method: 'GET',
            url: baseUrl + '/myTemplates',
            isArray: true}
    });
})
.factory('AdditionalDataModelResource', function ($resource) {
    var baseUrl = lax_rest_url_escaped('datamodel/additional');
    return $resource(baseUrl, {}, {
        'categories': {
            method: 'POST',
            url: baseUrl + '/categories'
        },
        'attributes': {
            method: 'POST',
            url: baseUrl + '/attributes'
        },
        'sectionattributes': {
            method: 'POST',
            url: baseUrl + '/sectionattributes',
            params: {layout: '@layout'}
        },
    });
})
.factory('MessagingResponsesResource', function ($resource) {
    return $resource(lax_rest_url_escaped('messaging/messagingassets/:messagingAssetId/messagingresponse'), {messagingAssetId: '@messagingAssetId'}, {});
})
.factory('ReleaseNotesResource',
  function($http) {
      var url = lax_rest_url('releaseNotes');
      return {
          get: function (callback) {
              $http({method: "GET", url: url})
                .success(function(data){
                      callback(data);
                  });
          }
      };
  }
)
.factory('DefaultItemResource', function ($resource) {
    return $resource(lax_rest_url_escaped('datamodel/defaultItem/:category'), {category: '@category'}, {});
})
.service('PhysicalAttributeService', function($filter) {

    this.splitValue = function(value) {
        if (_.isUndefined(value)) {
            return { inputValue: "", unitValue: "" };
        }
        var firstChar = value.search(/[ A-Za-z]/g);
        var inputValue = firstChar > -1 ? value.substr(0, firstChar) : value;
        var unitValue = firstChar > -1 ? value.substr(firstChar) : "";
        if (unitValue.length > 0 && unitValue[0] == ' ') {
            unitValue = unitValue.substr(1);
        }
        return { inputValue: inputValue, unitValue: unitValue };
    };

    this.parseValue = function(value, units) {
        if (_.isUndefined(value)) {
            return value;
        }
        var split = this.splitValue(value);
        var unitValue = replaceUnitValue(split.unitValue, units, 'value', 'key');
        var val = (split.inputValue || "") + unitValue;
        val = val.replace(/,/, '.');
        return val;
    };

    this.formatValue = function(value, units) {
        if (_.isUndefined(value)) {
            return value;
        }
        var split = this.splitValue(value);
        var unitValue = replaceUnitValue(split.unitValue, units, 'key', 'value');
        var val = "";
        if (split.inputValue && unitValue) {
            val = split.inputValue + " " + unitValue;
        } else {
            val = (split.inputValue || "") + (unitValue || "");
        }

        val = $filter('formatFloatValue')(val);

        return val;
    };

    function replaceUnitValue(unitValue, units, search, replace) {
        for ( var i = 0; i < units.length; i++) {
            var unit = units[i];
            if (unitValue == unit[search]) {
                return unit[replace];
            }
        }
        return unitValue;
    }

})
.service('AdditionalCategoryAttributeService', function($log, $rootScope, AdditionalDataModelResource) {

    this.loadAdditionalSectionAttributes = function(layoutName, item, callback) {

        // Check if additional categories were defined at all
        var itemAdditionalCategories = $rootScope.dataModel.getItemAdditionalCategories(item);
        if ($rootScope.isEmpty(itemAdditionalCategories)) {
            callback();
        }

        loadMissingAdditionalCategories(itemAdditionalCategories, function() {

            // Get additional section attributes from filter, if specified
            var layout = $rootScope.dataModel.layout(layoutName, item);
            var itemLayoutFilterName = _.get(layout, 'layoutOptions.layoutFilter');
            if (!_.isEmpty(itemLayoutFilterName)) {
                var itemLayoutFilter = $rootScope.getFilter(itemLayoutFilterName);
                if (!_.isFunction(itemLayoutFilter)) {
                    $log.warn("Item layout filter '" + itemLayoutFilterName + "' specified in layout '" + layoutName + "' does not exist");
                } else {
                    var additionalSectionAttributes = itemLayoutFilter(layout, item, $rootScope.user, $rootScope.organization);
                    callback(additionalSectionAttributes);
                    return;
                }
            }

            // Get additional section attributes from backend
            AdditionalDataModelResource.sectionattributes({'layout': layoutName}, item, function(additionalSectionAttributes) {
                callback(additionalSectionAttributes);
            });

        });

    };

    function loadMissingAdditionalCategories(itemAdditionalCategories, callback) {
        var missingCategories = $rootScope.dataModel.getMissingAdditionalCategories(itemAdditionalCategories);
        if ($rootScope.isEmpty(missingCategories)) {
            loadMissingAdditionalAttributes(itemAdditionalCategories, callback);
        } else {
            AdditionalDataModelResource.categories(missingCategories, function(additionalCategories) {
                $rootScope.dataModel.addAdditionalCategories(additionalCategories.toJSON());
                loadMissingAdditionalAttributes(itemAdditionalCategories, callback);
            });
        }
    }

    function loadMissingAdditionalAttributes(itemAdditionalCategories, callback) {
        var missingAttributes = $rootScope.dataModel.getMissingAdditionalAttributes(itemAdditionalCategories);
        if ($rootScope.isEmpty(missingAttributes)) {
            callback();
        } else {
            AdditionalDataModelResource.attributes(missingAttributes, function(additionalAttributes) {
                $rootScope.dataModel.addAdditionalAttributes(additionalAttributes.toJSON());
                callback();
            });
        }
    }

})
.factory('RealtimeResource', function ($resource) {
    return $resource(lax_rest_url_escaped('realtime/:action/:event'), {event: '@event', identifier: '@identifier'}, {
        settings: {method: 'GET', params: {action: 'settings'}},
        subscribe: {method: 'POST', params: {action: 'subscribe'}},
        subscribeMultiple: {method: 'POST', params: {action: 'subscribe'}},
        unsubscribe: {method: 'POST', params: {action: 'unsubscribe'}},
        unsubscribeMultiple: {method: 'POST', params: {action: 'unsubscribe'}},
        listen: {method: 'POST', params: {action: 'listen'}},
        unlisten: {method: 'POST', params: {action: 'unlisten'}},
        subscribedUsers: {method: 'GET', params: {action: 'subscribedUsers'}, isArray: true}
    });
})
.service('ChannelService', function($log, $q, $rootScope, $timeout, RealtimeResource, RealtimeProvider) {

    this.BROADCAST_MESSAGE = "broadcastMessage";
    this.BULK_SEARCH_CHANGED_EVENT = "bulkSearchChanged";
    this.CHAT_EVENT = "chat";
    this.DATA_MODEL_ACTIVATEABLE_EVENT = "dataModelActivateable";
    this.DATA_MODEL_CHANGED_EVENT = "dataModelChanged";
    this.DATA_MODEL_INVALID_EVENT = "dataModelInvalid";
    this.DATA_MODEL_TEMPLATE_INVALID_EVENT = "dataModelTemplateInvalid";
    this.EXPORT_SUMMARY_CHANGED_EVENT = "exportSummaryChanged";
    this.FINISHED_VIRUS_SCAN_ON_ASSET = "finishedVirusScanOnAsset";
    this.IMPORT_SUMMARY_CHANGED_EVENT = "importSummaryChanged";
    this.ITEM_CHANGED_EVENT = "itemChanged";
    this.ITEM_EDITED_EVENT = "itemEdited";
    this.MASS_UPDATE_SUMMARY_CHANGED_EVENT = "massUpdateSummaryChanged";
    this.MESSAGING_ASSET_CHANGED_EVENT = "messagingAssetChanged";
    this.NEAR_ITEM_LIMIT_EVENT = "nearItemLimit";
    this.NEWS_STREAM_CHANGED_EVENT = "newsStreamChanged";
    this.PUBLICATION_JOB_CHANGED_EVENT = "publicationJobChanged";
    this.PUBLICATION_TASK_CHANGED_EVENT = "publicationTaskChanged";
    this.TASK_ASSIGNED_EVENT = "taskAssigned";
    this.USERGROUPS_CHANGED = "userGroupsChanged";
    this.VALIDATION_FINISHED_EVENT = "validationFinished";
    this.VALIDATION_TASK_CHANGED_EVENT = "validationTaskChanged";

    this.SUBSCRIBE_TYPE = "subscribe";
    this.LISTEN_TYPE = "listen";
    this.REGISTERED_ACTION = "registered";
    this.UNREGISTERED_ACTION = "unregistered";

    var currentRegistrations = {};

    var self = this;

    var currentSubscribePromise = null;
    var currentListenPromise = null;

    this.connect = function(callback) {
        RealtimeResource.settings({}, function (settings) {
            RealtimeProvider.connect(settings, callback);
        });

    };

    this.disconnect = function() {
        RealtimeProvider.disconnect();
        currentRegistrations = {};
        navigator.sendBeacon(lax_rest_url("realtime/disconnect"));
    };

    this.isConnected = function() {
        return RealtimeProvider.isConnected();
    };

    this.isRegistered = function(event, identifier) {
        return _.some(currentRegistrations, function(entry) {
            return entry.event == event && (_.isNil(identifier) || entry.identifier == identifier);
        });
    };

    this.register = function(event, identifier, listen) {

        self._waitUntilSubscribeIdle(function() {
            $log.debug("ChannelService begin register: event=", event, ", identifier=", identifier, ", listen=", listen);
            return RealtimeResource.subscribe({event: event, identifier: identifier}, {}, function(response) {
                $log.debug("ChannelService end register: event=", event, ", identifier=", identifier, ", listen=", listen);
                self._subscribe(response.eventName, event, identifier);
            });
        });

        if (listen) {
            self._waitUntilListenIdle(function() {
                return RealtimeResource.listen({event: event, identifier: identifier}, {}, function(response) {
                    self._subscribe(response.eventName, event, identifier);
                });
            });
        }

    };

    this.registerMultiple = function(events) {
        self._waitUntilSubscribeIdle(function() {
            $log.debug("ChannelService begin registerMultiple: events=", events);
            return RealtimeResource.subscribeMultiple({}, events, function(response) {
                $log.debug("ChannelService end registerMultiple: events=", events);
                _.forEach(response.toJSON(), function(eventName, event) {
                   self._subscribe(eventName, event);
                });
            });
        });
    };

    this.unregister = function(event, identifier, listen) {

        if (!_.isEmpty(currentRegistrations)) {

            self._waitUntilSubscribeIdle(function() {
                $log.debug("ChannelService begin unregister: event=", event, ", identifier=", identifier, ", listen=", listen);
                return RealtimeResource.unsubscribe({event: event, identifier: identifier}, {}, function(response) {
                    $log.debug("ChannelService end unregister: event=", event, ", identifier=", identifier, ", listen=", listen);
                    self._unsubscribe(response.eventName, event, identifier);
                });
            });

            if (listen) {
                self._waitUntilListenIdle(function() {
                    return RealtimeResource.unlisten({event: event, identifier: identifier}, {}, function(response) {
                        self._unsubscribe(response.eventName, event, identifier);
                    });
                });
            }

        }
    };

    this.getRegistered = function(event, identifier) {
        var defer = $q.defer();
        self._waitUntilConnected(function() {
           RealtimeResource.subscribedUsers({event: event, identifier: identifier}, function(response) {
               defer.resolve(response);
           });
        });
        return defer.promise;
    };

    this.broadcast = function(event, identifier, data) {
        var defer = $q.defer();
        self._waitUntilConnected(function() {
            RealtimeProvider.emit(event, identifier, self.SUBSCRIBE_TYPE, data);
            defer.resolve();
        });
        return defer.promise;
    };

    this._waitUntilSubscribeIdle = function(resourceActionCallback) {
        if (currentSubscribePromise && !currentSubscribePromise.$$state.status) {
            currentSubscribePromise = currentSubscribePromise.finally(resourceActionCallback);
        } else {
            currentSubscribePromise = resourceActionCallback().$promise;
        }
    };

    this._waitUntilListenIdle = function(resourceActionCallback) {
        if (currentListenPromise && !currentListenPromise.$$state.status) {
            currentListenPromise = currentListenPromise.finally(resourceActionCallback);
        } else {
            currentListenPromise = resourceActionCallback().$promise;
        }
    };

    this._subscribe = function(eventName, event, identifier) {
        self._waitUntilConnected(function() {
            RealtimeProvider.subscribe(eventName, self._subscribeCallback);
            currentRegistrations[eventName] = {event: event, identifier: identifier};
        });
    };

    this._unsubscribe = function(eventName, event, identifier) {
        self._waitUntilConnected(function() {
            RealtimeProvider.unsubscribe(eventName);
            delete currentRegistrations[eventName];
        });
    };

    this._subscribeCallback = function(eventData) {
        $log.debug("ChannelService subscribeCallback.eventData:", eventData);
        $timeout(function() {
            $rootScope.$broadcast('channelMessageReceived', eventData);
        });
    };

    this._waitUntilConnected = function(callback) {

        if (self.isConnected()) {
            callback();
            return;
        }

        var waitUntil = {
            callback: callback
        };
        waitUntil.watch = $rootScope.$watch(self.isConnected, function(newValue, oldValue) {
            if (newValue) {
                self._handleConnected(waitUntil);
            }
        });
    };

    this._handleConnected = function(waitUntil) {
        if (waitUntil.watch) {
            waitUntil.watch();
        }
        waitUntil.callback();
    };

})
.factory('RolesResource', function($rootScope, $http, $resource) {
    return $resource(lax_rest_url("rolenames"), {});
})

.service('RolesService', function($rootScope, $q, $cacheFactory, RolesResource) {

    var rolesFactory = $cacheFactory('roles');
    var roles_ = null;

    this.reloadAllRoles = function() {
        rolesFactory.remove('all-roles');
        roles_ = null;
        this.getAllRoles().then(function(roles) {
            roles_ = roles;
        });
    };

    this.getAllRoles = function() {
        return this.loadAllRoles('all-roles');
    };

    this.loadAllRoles = function(key) {
        if (_.isEmpty(key)) {
            return $q.when({});
        }
        if (roles_) {
            return $q.when(roles_);
        }
        var rolesData = rolesFactory.get(key);

        if (_.isNil(rolesData)) {

            var query = RolesResource.query({});

            var promise = query.$promise.then(function(response) {
                roles_ = response;
                rolesFactory.put(key, response);
                return response;
            }, function(errorReason) {
                // FIXME: Depending on the error we might want to retry the query
                return {};
            });

            // Cache the promise to reduce network traffic
            if (!query.$resolved) {
                rolesData = {
                    promise: promise
                };
                rolesFactory.put(key, rolesData);
            }

            return promise;
        }

        if (rolesData.promise) {
            return rolesData.promise;
        } else {
            roles_ = rolesData;
            return $q.when(rolesData);
        }
    };
})
.factory('TourService', function($http, $q) {
    this.setTourCompleted = function(user, tour, active) {
        return $http.put(lax_rest_url('users/' + user.id + '/completedTours/' + tour + '/' + active) , {});
    };
    this.loadTours = function() {
        var deferred = $q.defer();
        $http.get(lax_rest_url('tours'), {includeDataModelTours : true, includeReleaseTours: true}).then(function(results) {

              var tours = JSON.parse(results.data.tours);
              var datamodelTours = results.data.dataModelTours;
              if (datamodelTours) {
                  tours = tours.concat(JSON.parse(datamodelTours));
              }

              var releaseTours = results.data.releaseToursTours;
              if (releaseTours) {
                  releaseTours = JSON.parse(releaseTours);
                  // Hide all release tours and only start the tour for the current version automatically
                  angular.forEach(releaseTours, function(releaseTour) {
                      if (_.isEmpty(releaseTour.version)) {
                          console.log("ReleaseTour", releaseTour.id, " does not have a version defined! Ignoring it!");
                          return;
                      }

                      releaseTour.hidden = true;
                      releaseTour.showCloseButton = true;
                      releaseTour.closeWithoutConfirm = true;
                      if (releaseTour.version === __laxVersion__) {
                          releaseTour.startAutomatically = true;
                      } else {
                          releaseTour.startAutomatically = false;
                      }
                  });

                  tours = tours.concat(releaseTours);
              }

              deferred.resolve(tours);
          });
          return deferred.promise;
      };
    return this;
})
.factory('InputTemplatesService', function($rootScope, $http, $injector, $log) {
    this.loadTemplates = function(callback) {
        if (!$rootScope.inputTemplatesRegistered) {

            // TODO: Add etag 'caching'!
            $log.info("Loading 'customTemplates.html'");
            $http.get(lax_rest_url('datamodel/customTemplates.html'))
                .then(function (response) {
                    // compile the response, which will put stuff into the cache
                    $injector.get('$compile')(response.data);
                    $rootScope.inputTemplatesRegistered = true;
                    $log.info("Loaded 'customTemplates.html'");
                    $rootScope.$broadcast('templatesLoaded');
                    if (callback) {
                        callback();
                    }
                });

        } else {
            if (callback) {
                callback();
            }
            $rootScope.$broadcast('templatesLoaded');
        }
    };
    return this;
})
.factory('FeatureToggleService', function(growl) {

    var ENABLED_FEATURES = 'enabledFeatures';

    var availableFeatures = [
        "deleteTaskList",
        "depublish",
        "editorSettingsPreview",
        "guided-tour",
        "legacyDataModels",
        "reports"
    ];

    function featureExists(feature) {
        var exists = availableFeatures.contains(feature);
        if (!exists) {
            var availableFeaturesList = "<ul>";
            for (var i = 0; i < availableFeatures.length; i++) {
                availableFeaturesList += "<li>" + availableFeatures[i] + "</li>";
            }
            availableFeaturesList += "</ul>";
            growl.error("FEATURE.FEATURE_NOT_FOUND_ERROR", { variables: { feature: feature, list: availableFeaturesList } });
        }
        return exists;
    }

    function readFromStorage() {
        return JSON.parse(sessionStorage.getItem(ENABLED_FEATURES) ||'[]');
    }
    function writeToStorage(features) {
        return sessionStorage.setItem(ENABLED_FEATURES, JSON.stringify(features));
    }

    this.checkFeatureEnabled = function(feature) {
            var enabledFeatures = readFromStorage();
            return enabledFeatures.contains(feature);
    };
    this.enableFeature = function(feature) {
         if (featureExists(feature)) {
             var enabledFeatures = readFromStorage();
             enabledFeatures.addToSet(feature);
             writeToStorage(enabledFeatures);
             growl.success("Feature " + feature + " enabled");

         }
    };
    this.disableFeature = function(feature) {
         if (featureExists(feature)) {
             var enabledFeatures = readFromStorage();
             enabledFeatures.removeByContent(feature);
             writeToStorage(enabledFeatures);
             growl.success("Feature " + feature + " disabled");
         }
    };
    return this;
})
.service('SessionDataService', function() {
    this.put = function(key, value) {
        var keys = JSON.parse(sessionStorage.getItem('keys')) || [];
        keys.addToSet(key);
        keys = JSON.stringify(keys);
        // update keys in sessionStorage
        sessionStorage.setItem('keys', keys);
        // set actual key, value
        sessionStorage.setItem(key, value);
    };
    this.get = function(key) {
        return sessionStorage.getItem(key);
    };
    // clear all user specific data
    // we don't want sessionStorage.clear() here,
    // because we want to keep things like dataModelHash
    this.clearKeys = function() {
        var keys = JSON.parse(sessionStorage.getItem('keys')) || [];
        angular.forEach(keys, function(key, index) {
            sessionStorage.removeItem(key);
            if (index == keys.length-1) {
                sessionStorage.removeItem('keys');
            }
        });
    };
})
.factory('$dialogs', ['$modal', function ($modal) {
    return {
        confirm: function (header, msg, options) {
            return $modal.open({
                templateUrl: 'tpl/confirm-dialog.tpl.html',
                controller: 'ConfirmDialogController',
                windowClass: 'confirmation-window',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    header: function () {
                        return angular.copy(header);
                    },
                    msg: function () {
                        return angular.copy(msg);
                    },
                    notification: function () {
                        return false;
                    },
                    options: function () {
                        return options;
                    }
                }
            });
        },
        notify: function (header, msg, options) {
            return $modal.open({
                templateUrl: 'tpl/confirm-dialog.tpl.html',
                controller: 'ConfirmDialogController',
                resolve: {
                    header: function () {
                        return angular.copy(header);
                    },
                    msg: function () {
                        return angular.copy(msg);
                    },
                    notification: function () {
                        return true;
                    },
                    options: function () {
                        return options;
                    }
                }
            });
        }
    };
}])
.factory('ShoppingCartResource', function ($resource) {
    return $resource(lax_rest_url_escaped('shoppingCarts/:id'), { });
})
.factory('ShoppingCartItemResource', function ($resource) {
    return $resource(lax_rest_url_escaped('shoppingCarts/shoppingCartItems/:id'), { });
})
.factory('ConvertShoppingCartItemResource', function ($resource) {
    return $resource(lax_rest_url_escaped('shoppingCarts/shoppingCartItem/generate/:primaryKey'), { });
})
.service('ShoppingCartService', function(ShoppingCartResource, ShoppingCartItemResource, ConvertShoppingCartItemResource, growl, $translate) {

    var self = this;

    self.shoppingCart = {
        items : []
    };

    function getShoppingCartItem(item, quantity, callback) {
        ConvertShoppingCartItemResource.get({primaryKey: item.primaryKey__, quantity:quantity}, function(response) {
            if (!response.primaryKey) {
                growl.error("SHOPPING_CART.ERROR_ADDING_ITEM_CALCULATION_ERROR");
            } else {
                response.shoppingCartId = self.shoppingCart.id;
                growl.success("SHOPPING_CART.ADDED_ITEM");
                callback(response);
            }
        }, function(error) {
            growl.error("SHOPPING_CART.ERROR_ADDING_ITEM", { variables: { error: error.data } } );
        });
    }

    this.addItem = function (item, price, quantity) {

        quantity = quantity || 1;

        var existingItem = self.shoppingCart.items.filter(function (elem) {
            return item.primaryKey__ === elem.primaryKey;
        })[0];

        if (existingItem && existingItem.quantity) {
            quantity += parseFloat(existingItem.quantity);
        }

        getShoppingCartItem(item, quantity, function (itemToSave) {

            itemToSave.id = existingItem ? existingItem.id : null;

            ShoppingCartItemResource.save(itemToSave, function (savedItem) {

                item.id = savedItem.id;

                if (existingItem) {
                    angular.extend(existingItem, savedItem);
                } else {
                    self.shoppingCart.items.push(savedItem);
                }
            });
        });

    };

    this.removeItem = function (itemToRemove) {
        ShoppingCartItemResource.remove({id: itemToRemove.id}, function(response){
            self.shoppingCart.items = self.shoppingCart.items.filter(function (item) {
                return item.id !== itemToRemove.id;
            });
        });
    };

    this.getTotalPrice = function() {
        var total = self.shoppingCart.items.reduce(function (previousValue, item) {
            // TODO: implement on server side to get precise total price
            return previousValue + item.price * item.quantity;
        }, 0);
        return total.toFixed(2);
    };

    this.updateTotalPrice = function (itemToUpdate) {
        var matchedItem = self.shoppingCart.items.filter(function (item) {
            return item.item.primaryKey__ === itemToUpdate.item.primaryKey__;
        })[0];
        if(matchedItem) {
            matchedItem.totalPrice = itemToUpdate.price * itemToUpdate.quantity;
        }
    };

    this.getColumnDefinitions = function() {
        var columnDefs = [];

        columnDefs.push(
            {
                field: "description",
                displayName: $translate.instant('SHOPPING_CART.TRADE_ITEM_DESCRIPTION'),
                headerTooltip: true,
                cellTooltip: true,
                enableCellEdit: false,
                resizable: true
            }
        );
        columnDefs.push(
            {
                field: "quantity",
                displayName: $translate.instant('QUANTITY'),
                headerTooltip: true,
                enableCellEdit: true,
                resizable: true
            }
        );
        columnDefs.push(
            {
                field: "price",
                displayName: $translate.instant('PRICE'),
                headerTooltip: true,
                enableCellEdit: false,
                resizable: true,
                cellFilter: 'currency'
            }
        );
        columnDefs.push(
            {
                field: "currency",
                displayName: $translate.instant('CURRENCY'),
                headerTooltip: true,
                enableCellEdit: false,
                resizable: true,
                cellFilter: 'currency'
            }
        );
        columnDefs.push(
            {
                field: "totalPrice",
                displayName: $translate.instant('SHOPPING_CART.TOTAL_PRICE'),
                headerTooltip: true,
                enableCellEdit: false,
                resizable: true,
                cellFilter: 'currency'
            }
        );
        return columnDefs;
    };

})
.service('OrdersService', function() {
    this.orders = initOrders();
    function Order(id, date, status, remark, positions) {
        this.id = id;
        this.date = date;
        this.status = status;
        this.remark = remark;
        this.positions = positions;
        this.total = this.positions.reduce(function(result, position) {
            return result + position.totalPrice;
        }, 0);
    }
    function OrderPosition(product, price, quantity) {
        this.product = product;
        this.price = price;
        this.quantity = quantity;
        this.totalPrice = price * quantity;
    }
    // TODO: replace this with real data
    function initOrders() {
        return [
            new Order("2345", new Date(), "In Process", "Looking forward", [
                new OrderPosition(
                    {
                        primaryKey__: "3434324324:324324324324:278",
                        supplier: "UHU",
                        gtin: "234568",
                        brandName: "UHU",
                        tradeItemDescription: "glue stic"
                    },
                    3.99,
                    5
                ),
                new OrderPosition(
                    {
                        primaryKey__: "3434324324:324324324324:279",
                        supplier: "UHU",
                        gtin: "234568",
                        brandName: "UHU",
                        tradeItemDescription: "glue stic"
                    },
                    3.99,
                    5
                )
            ]),
            new Order("2346", new Date(), "In Process", "Looking forward", [
                new OrderPosition(
                    {
                        primaryKey__: "3434324324:324324324324:278",
                        supplier: "UHU",
                        gtin: "234568",
                        brandName: "UHU",
                        tradeItemDescription: "glue stic"
                    },
                    3.99,
                    5
                ),
                new OrderPosition(
                    {
                        primaryKey__: "3434324324:324324324324:279",
                        supplier: "UHU",
                        gtin: "234568",
                        brandName: "UHU",
                        tradeItemDescription: "glue stic"
                    },
                    3.99,
                    5
                )
            ])
        ];
    }
    this.getOrder = function(orderToFind, callback) {
        console.log(this.orders);
        console.log(orderToFind);
        var ordersFound = $.grep(this.orders, function(order) {
            return order.id === orderToFind.id;
        });
        console.log("ordersFound");
        console.log(ordersFound);
        var result = (ordersFound && ordersFound.length) > 0 ? ordersFound[0] : new Order();
        console.log(result);
        callback(result);
    };
})
.factory('ItemChangesQueue', function ($log, $rootScope, $timeout) {

    var ItemChangesQueue = function (queueName,
                                     delay,
                                     processorFn,
                                     skipProcessorFn,
                                     preprocessItemChangesFn) {

        var processedItem = null;

        var processingItem = null;

        var processorTimeout = null;

        var processorRunning = false;

        var queuedItemChanges = null;

        var originalItem = null;

        var pauseProcessor = false;

        var self = this;

        // Starts the queue using the specified item
        this.start = function (item) {

            if (self.isQueueRunning()) {
                $log.warn("Queue '" + queueName + "' is already running");
                return;
            }

            $log.debug("Starting queue: " + queueName);

            // Stop processor, if it was still running
            stopProcessorTimeout();

            originalItem = item;
            processedItem = angular.copy(item);
            queuedItemChanges = {};

        };

        // Stops the queue and returns the last processed item and queued changes
        this.stop = function () {

            if (!self.isQueueRunning()) {
                $log.warn("Queue '" + queueName + "' is not running");
                return;
            }

            $log.debug("Stopping queue: " + queueName);

            stopProcessorTimeout();

            var lastProcessedItem = processedItem;
            var lastQueuedItemChanges = queuedItemChanges;

            processedItem = null;
            queuedItemChanges = null;

            return {
                'lastProcessedItem': lastProcessedItem,
                'lastQueuedItemChanges': lastQueuedItemChanges
            };
        };

        function startProcessorTimeout(force) {
            if (self.isQueueRunning() && angular.isFunction(processorFn)) {

                // Check if processor timeout should be started
                if (!force && angular.isFunction(skipProcessorFn)) {
                    var skipProcessor = skipProcessorFn();
                    if (skipProcessor) {
                        return;
                    }
                }

                // Start processor timeout
                processorTimeout = $timeout(function () {

                    // Check if processor needs to run
                    if (!self.hasQueuedItemChanges()) {
                        return;
                    }
                    processorRunning = true;

                    // Copy item changes and reset queue
                    processingItem = angular.copy(processedItem);
                    var processingItemChanges = angular.copy(queuedItemChanges);
                    angular.extend(processingItem, processingItemChanges);
                    queuedItemChanges = {};

                    // Call processorFn and update processed item
                    processorFn(processingItemChanges, processingItem, processedItem, originalItem)
                        .then(function successCallback() {

                            // Update processed item
                            if (self.isQueueRunning()) {
                                processedItem = processingItem;
                            }

                        }, function errorCallback(reason) {

                            $log.error("Processor function for queue '" + queueName + "' failed due to: ", reason);

                            // Add changes to queue again
                            if (self.isQueueRunning()) {
                                self.queueItemChanges(processingItemChanges);
                            }

                        })
                        ['finally'](function () {

                            // Check if processor needs to restart
                            processorRunning = false;
                            if (self.hasQueuedItemChanges()) {
                                startProcessorTimeout();
                            }

                            processingItem = null;

                        });

                }, delay || 0);

            }
        }

        function stopProcessorTimeout() {
            if (processorTimeout) {
                $timeout.cancel(processorTimeout);
                processorTimeout = null;
            }
        }

        this.getQueueName = function () {
            return queueName;
        };

        this.getProcessedItem = function () {
            return processedItem;
        };

        this.getQueuedItemChanges = function () {
            return queuedItemChanges;
        };

        this.hasQueuedItemChanges = function () {
            return queuedItemChanges && Object.keys(queuedItemChanges).length > 0;
        };

        this.isProcessorRunning = function () {
            return processorRunning;
        };

        this.isQueueRunning = function () {
            return (processedItem !== null && queuedItemChanges !== null);
        };

        function isEqual(value1, value2) {
            return $rootScope.isEqual(value1, value2);
        }

        function isDefaultValue(key, newValue, oldValue) {
            return $rootScope.isDefaultValueForAttribute(key, newValue, oldValue);
        }

        function isEmpty(value) {
            return $rootScope.isEmpty(value);
        }

        this.queueItemChanges = function (itemChanges) {

            if (pauseProcessor) {
                $log.debug('Processor ' + self.getQueueName() + ' is paused, skipping queuing of changes: ', itemChanges);
                return;
            }

            if (angular.isFunction(preprocessItemChangesFn)) {
                itemChanges = preprocessItemChangesFn(itemChanges);
            }

            if (self.isQueueRunning()) {

                // Add item changes to queued changes
                angular.forEach(itemChanges, function (value, key) {

                    // Ignore angular properties and functions
                    if ((angular.isString(key) && key.charAt(0) === '$') || angular.isFunction(value)) {
                        return;
                    }

                    if (isEqual(processedItem[key], value) ||
                        (!_.isNil(processingItem) && isEqual(processingItem[key], value)) ||
                        (isEmpty(processedItem[key]) && isEmpty(value)) ||
                        (isDefaultValue(key, value, processedItem[key]))) {
                        delete queuedItemChanges[key];
                    } else {
                        queuedItemChanges[key] = value;
                    }

                });

                // Restart processor, if not currently running
                if (!processorRunning && !pauseProcessor) {
                    stopProcessorTimeout();
                    if (self.hasQueuedItemChanges()) {
                        startProcessorTimeout();
                    }
                }

            }

        };

        this.processItemChanges = function () {
            if (!self.isProcessorRunning() && self.hasQueuedItemChanges()) {
                startProcessorTimeout(true);
            }
        };

        this.pauseProcessor = function() {
            pauseProcessor = true;
        };

        this.continueProcessor = function() {
            pauseProcessor = false;
            stopProcessorTimeout();
            if (self.hasQueuedItemChanges()) {
                startProcessorTimeout();
            }
        };

    };

    return (ItemChangesQueue);
})
.factory('ItemChangesQueueManager', function ($log, ItemChangesQueue) {

    var ItemChangesQueueManager = function () {

        var queues = {};

        var unregisterWatch = null;

        this.createQueue = function (queueName,
                                     delay,
                                     processorFn,
                                     skipProcessorFn,
                                     preprocessItemChangesFn) {

            var queue = queues[queueName];
            if (queue) {
                $log.warn("Queue '" + queueName + "' already exists");
                return queue;
            }
            queue = new ItemChangesQueue(queueName,
                                         delay,
                                         processorFn,
                                         skipProcessorFn,
                                         preprocessItemChangesFn);
            queues[queueName] = queue;
            return queue;
        };

        this.start = function (scope, item, modelName, preprocessItemChangesFn) {

            // Start queues
            angular.forEach(queues, function (queue) {
                if (!queue.isQueueRunning()) {
                    queue.start(item);
                }
            });

            // Start watch
            if (!unregisterWatch) {
                this.startWatch(scope, modelName, preprocessItemChangesFn);
            }

        };

        this.startQueue = function (queueName, item) {
            var queue = queues[queueName];
            if (!queue) {
                $log.warn("Queue '" + queueName + "' to start does not exist");
                return;
            }
            if (!queue.isQueueRunning()) {
                queue.start(item);
            }
        };

        this.startWatch = function (scope, modelName, preprocessItemChangesFn) {

            if (unregisterWatch) {
                $log.warn("Watch was already started");
                return;
            }

            unregisterWatch = scope.$watch(modelName, function (itemChanges) {
                if (angular.isFunction(preprocessItemChangesFn)) {
                    itemChanges = preprocessItemChangesFn(itemChanges);
                }
                angular.forEach(queues, function (queue) {
                    queue.queueItemChanges(itemChanges);
                });
            }, true);

        };

        this.stop = function () {
            this.stopWatch();
            angular.forEach(queues, function (queue) {
                if (queue.isQueueRunning()) {
                    queue.stop();
                }
            });
        };

        this.stopQueue = function (queueName) {
            var queue = queues[queueName];
            if (!queue) {
                $log.warn("Queue '" + queueName + "' to stop does not exist");
                return null;
            } else if (queue.isQueueRunning()) {
                return queue.stop();
            } else {
                return {};
            }
        };

        this.stopWatch = function () {
            if (unregisterWatch) {
                unregisterWatch();
                unregisterWatch = null;
            }
        };

        this.getQueue = function (queueName) {
            return queues[queueName];
        };

    };

    return (ItemChangesQueueManager);
})
.service('ValidateItemService', function($log, $rootScope, BackendValidateItemResource, ValidationResource, ValidateItemsResource) {

    this.validateItem = function (scope, originalScopeItem, newItem, oldItem, itemAttributeStates, itemValidations, attributeNames, doBeforeItemUpdate) {

        var validationParams = {
            newItem: newItem,
            oldItem: oldItem,
            attributeNames: attributeNames
        };

        var resource = $rootScope.systemSettings.VALIDATION_USE_DEDICATED_BACKEND_SERVICE ? BackendValidateItemResource : ValidationResource;

        return resource.validate({}, validationParams,
            function (validationResponse) {

                // We can use `doBeforeItemUpdate` to pause any change queues in case
                // we want to update the item due to the calculated attributes
                if(!_.isNil(doBeforeItemUpdate) && _.isFunction(doBeforeItemUpdate)) {
                    doBeforeItemUpdate();
                }

                $log.debug("validationResponse=", validationResponse);

                if (_.isEmpty(attributeNames)) {
                    // If we are validating the complete item, we reset all the attribute states.
                    // note that we need to clear the properties as the object reference is passed by value!
                    clearAllObjectProperties(itemAttributeStates);

                    // and reset the item validations too
                    clearAllObjectProperties(itemValidations);

                }

                // Merge all attribute states from response
                if (itemAttributeStates) {
                    angular.forEach(validationResponse.attributeStates, function (attributeStates, attributeName) {
                        updateAttributeStates(itemAttributeStates, attributeStates, attributeName);
                    });
                }

                // Merge all validations from response
                angular.forEach(validationResponse.validations, function (attributeValidations, attributeName) {
                    updateValidations(itemValidations, attributeValidations, attributeName);
                });

                // Set all calculated values from response
                if (validationResponse.calculations) {
                    angular.forEach(validationResponse.calculations, function (calculatedAttributeValue, attributeName) {
                        updateCalculatedAttribute(originalScopeItem, calculatedAttributeValue, attributeName);
                    });
                }

                scope.$broadcast("validation_finished");

            }, function (response) {
                $log.error("Got error response with status '" + response.status + "': ", response);
                scope.status = response.status;
            });
    };

    this.validateItemSelection = function(selectedItems, successFn, errorFn) {
        return ValidateItemsResource.validate({}, selectedItems, successFn, errorFn);
    };

    function updateAttributeStates(itemAttributeStates, attributeStates, attributeName) {
        if (!attributeStates || attributeStates.length === 0) {
            delete itemAttributeStates[attributeName];
        } else {
            itemAttributeStates[attributeName] = attributeStates;
        }
    }

    function updateValidations(itemValidations, attributeValidations, attributeName) {
        if (!attributeValidations || attributeValidations.length === 0) {
            delete itemValidations[attributeName];
        } else {
            $rootScope.translateAttributeValidations(attributeName, attributeValidations);
            itemValidations[attributeName] = attributeValidations;
        }
    }

    function updateCalculatedAttribute(item, calculatedAttributeValue, attributeName) {
        item[attributeName] = calculatedAttributeValue;
    }

    function defaultCellValidations(row, col) {
        return [];
    }

    this.defineGridValidationMethods = function (scope, cellValidationsFn) {

        cellValidationsFn = cellValidationsFn || defaultCellValidations;

        scope.isCellInvalid = function (row, col) {
            var cellValidations = cellValidationsFn(row, col);
            return cellValidations && cellValidations.length > 0;
        };

        scope.getCellValidationMessages = function (row, col) {

            var cellValidations = cellValidationsFn(row, col);
            if (_.isEmpty(cellValidations)) {
                return null;
            }

            var message;
            var errorMessages = '';
            var failureMessages = '';
            var warningMessages = '';

            angular.forEach(cellValidations, function (validation) {
                message = '\n<li>' + (validation.translatedMessage || $rootScope.translateValidationMessage(validation)) + '</li>';
                if (validation.level === 'Error') {
                    errorMessages += message;
                } else if (validation.level === 'Failure') {
                    failureMessages += message;
                } else if (validation.level === 'Warning') {
                    warningMessages += message;
                }
            });

            var messages = '<ul>' + errorMessages + failureMessages + warningMessages + '\n</ul>';

            return messages;
        };

        // Only used for really old stuff unaware of different validation levels!
        scope.isRowInvalid = function (row) {
            return !_.isEmpty(row.entity.validation__);
        };

        scope.getRowValidationMessages = function (row) {

            if (_.isEmpty(row.entity.validation__) && _.isEmpty(row.entity.validation_warnings__)) {
                return null;
            }

            var validationErrors = '';
            var validationWarnings = '';

            angular.forEach(row.entity.validation__, function (validation) {
                validationErrors += '\n<li>' + $rootScope.translateValidationLabel(validation) + '</li>';
            });
            angular.forEach(row.entity.validation_warnings__, function (validation) {
                validationWarnings += '\n<li>' + $rootScope.translateValidationLabel(validation) + '</li>';
            });

            var messages = '<ul>' + validationErrors + validationWarnings + '\n</ul>';

            return messages;
        };

    };

    function clearAllObjectProperties(object) {
        _.forOwn(object, function(_, prop) { delete object[prop]; });
    }
})
.factory('DataModelRetrievalResource', function($resource) {
    return $resource(lax_rest_url_escaped('datamodel/retrieval/:method'), {
        method: '@method',
        dataModel: '@dataModel',
        parent: '@parent',
        leaf: '@leaf',
        extension: '@extension',
        limit: '@limit',
        q: '@query'
    }, { query: { method: 'GET', isArray: true } });
})
.factory('TabContainer', function() {
    function TabContainer(scope, location, tabs, defaultTab, onTabActivate) {
        var self = this;
        this.scope = scope;
        this.location = location;

        this.onTabActivate = onTabActivate;

        this.tabs = tabs;

        function tabActivateCallback() {
            if (self.onTabActivate) {
                self.onTabActivate(scope.currentTab);
            }
        }

        function addTab(tab) {
            self.tabs.push(tab);
        }

        function getTabBySuburl(suburl) {
           return tabs.filter(function (tab) {
                return tab.suburl === suburl;
            })[0];
        }

        for (var i= 0; i < tabs.length; i++){
            //by default all tabs are visible
            tabs[i].isVisible = tabs[i].isVisible || function() { return true; };
        }
        scope.tabs = tabs;

        if (defaultTab) {
            scope.currentTab = getTabBySuburl(defaultTab);
        } else {
            scope.currentTab = tabs[0];
        }
        tabActivateCallback();

        scope.onClickTab = function (tab) {
            scope.currentTab = tab;
            tabActivateCallback();
            if (tab.suburl !== undefined){
                location.hash(tab.suburl);
            }
        };

        scope.isActiveTab = function(tab) {
            return tab.suburl == scope.currentTab.suburl;
        };

        this.openSubview = function(suburl){
            if (suburl === undefined){
                return;
            }

            for(var i = 0; i< tabs.length; i++){
                var tab = tabs[i];
                if (tab.suburl === suburl){
                    scope.currentTab = getTabBySuburl(tab.suburl);
                    tabActivateCallback();
                }
            }
        };
    }
    return TabContainer;
})
.factory('MultiMap', function() {
    function MultiMap() {

        this.content = {};

        this.add = function(key, value) {
            this.content[key] = this.content[key] || [];
            this.content[key].push(value);
        };

        this.get = function(key) {
            return this.content[key];
        };

        this.keySet = function() {
            return Object.keys(this.content);
        };
    }
    return MultiMap;
})
.service('WatchlistService', function($log, $window) {

    var ITEM_WATCHLIST = "itemWatchlist";
    var LocalStorage = $window.localStorage;

    var watchlist = {};

    this.getWatchlistPrimaryKeys = function() {
        return watchlist.primaryKeys || [];
    };

    this.getWatchlistCategories = function() {
        return watchlist.categories || [];
    };

    this.getWatchlistItemCount = function() {
        return _.size(watchlist.primaryKeys);
    };

    this.hasWatchlistItems = function() {
        return !_.isEmpty(watchlist.primaryKeys);
    };

    this.clearWatchlist = function() {
        _setWatchlist();
        _saveWatchlist();
    };

    this.addWatchlistItem = function(item) {
        _addWatchlistItem(item);
        _saveWatchlist();
    };

    this.removeWatchlistItem = function(item) {
        _removeWatchlistItem(item);
        _saveWatchlist();
    };

    this.removeWatchlistItems = function(items) {
        _.forEach(items, function(item) {
            _removeWatchlistItem(item);
        });
        _saveWatchlist();
    };

    this.setWatchlistItems = function(items) {
        setWatchlist({});
        _.forEach(items, function(item) {
            _addWatchlistItem(item);
        });
        _saveWatchlist();
    };

    function _addWatchlistItem(item) {

        var primaryKey = item.primaryKey__;
        var category = item.category__;

        if (_.includes(watchlist.primaryKeys, primaryKey)) {

            var oldCategory = watchlist.primaryKeyCategory[primaryKey];
            if (oldCategory == category) {
                return;
            }

            _removeCategory(primaryKey, oldCategory);

        } else {
            watchlist.primaryKeys.push(primaryKey);
        }

        watchlist.primaryKeyCategory[primaryKey] = category;
        watchlist.categoryPrimaryKeys[category] = (watchlist.categoryPrimaryKeys[category] || []);
        if (!_.includes(watchlist.categoryPrimaryKeys[category], primaryKey)) {
            watchlist.categoryPrimaryKeys[category].push(primaryKey);
        }
        if (!_.includes(watchlist.categories, category)) {
            watchlist.categories.push(category);
        }

    }

    function _removeWatchlistItem(item) {

        var primaryKey = _.isObject(item) ? item.primaryKey__ : item;
        if (!_.includes(watchlist.primaryKeys, primaryKey)) {
            return;
        }

        _.pull(watchlist.primaryKeys, primaryKey);
        var oldCategory = watchlist.primaryKeyCategory[primaryKey];
        delete watchlist.primaryKeyCategory[primaryKey];

        _removeCategory(primaryKey, oldCategory);

    }

    function _removeCategory(primaryKey, category) {
        _.pull(watchlist.categoryPrimaryKeys[category], primaryKey);
        if (_.isEmpty(watchlist.categoryPrimaryKeys[category])) {
            delete watchlist.categoryPrimaryKeys[category];
            _.pull(watchlist.categories, category);
        }
    }

    function _loadWatchlist() {
        var json = LocalStorage.getItem(ITEM_WATCHLIST);
        var items = angular.fromJson(json);
        if (_.isArray(items)) {
            // Watchlist was in old format (array of primary keys)
            items = {};
        }
        _setWatchlist(items);
    }

    function _saveWatchlist() {
        var json = angular.toJson(watchlist);
        LocalStorage.setItem(ITEM_WATCHLIST, json);
    }

    function _setWatchlist(items) {
        watchlist = (items || {});
        watchlist.primaryKeys = (watchlist.primaryKeys || []);
        watchlist.categories = (watchlist.categories || []);
        watchlist.primaryKeyCategory = (watchlist.primaryKeyCategory || {});
        watchlist.categoryPrimaryKeys = (watchlist.categoryPrimaryKeys || {});
    }

    (function () {
        _loadWatchlist();
    })();

})
.factory('MediaAssetResource', function($resource) {
    var baseUrl = lax_rest_url_escaped('mediaAssets/');
    return $resource(
        baseUrl,
        {},
        {
            getBySelection: {method: 'GET', url: baseUrl + 'selected/:selectionId'},
            getNamingPatterns: {method: 'GET', url: baseUrl + 'namingPatterns'},
            export: {method: 'POST', url: baseUrl + 'export'}
        }
    );
})
.factory('ItemHierarchyResource', function($resource) {
    var url = lax_rest_url_escaped('items') + '/:primaryKey' + '/hierarchies';
    return $resource(url, {primaryKey: '@primaryKey', hierarchyName: '@hierarchyName'}, {
        get: {method: 'GET'},
        post: {method: 'POST', params: {hierarchyName: '@hierarchyName'} }
    });
})
.factory('MyTasksFactory', function(GenericDashletFactory, TaskListsResource) {
    return {
        create: function(config) {
            config = config || {};
            config.fetchData = function(callback) {
                var model = {};
                model.tasks = [];
                TaskListsResource.query({}, function(response) {
                    response.forEach(function(list, index, arr) {
                        TaskListsResource.query({
                            taskListId: list.id
                        }, function(tasks) {
                            tasks.forEach(function(task, i2, arr) {
                                if (config.user.userId == task.assignee && task.taskStatus !=
                                    "FINISHED") {
                                    model.tasks.push(task);
                                }
                            });
                        });
                    });
                    callback(model);
                });
            };
            return GenericDashletFactory.create(config);
        }
    };
})
.factory('CheckURLRessource', function($resource) {
    var baseUrl = lax_rest_url('checkUrl');
    return $resource(baseUrl, {
        get: {method: 'GET'}
    });
})
.factory('GetUrlInfo', function($resource) {
    var baseUrl = lax_rest_url('getUrlInfo');
    return $resource(baseUrl, {
        get: {method: 'GET'}
    });
})
.factory('QueryKeyValueMapRessource', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('keyValueMaps/:map');
})
.factory('KeyValueMapRessource', function($resource) {
    var url = lax_rest_url('keyValueMaps');
    return $resource(url, {}, {
        getAllMapNames: {method: 'GET', isArray: true},
        getValue: {method: 'GET', url: url + '/:map/:key'},
        updateValue: {method: 'PUT', url: url + '/:map/:key'},
        delete: {method: 'DELETE', url: url + '/:map/:key'}
    });
})

.factory('CountItemsResource', function($resource) {
    var url = lax_rest_url('items/count');
    return $resource(url, {}, {
      get: {method: 'GET', url: url + '/:selectionId', isArray: false},
      count: {method: 'POST', isArray: false}
    });
})

.factory('CountMessageResource', function($resource) {
    var url = lax_rest_url('messaging/messagingassets/count');
    return $resource(url, {}, {
      get: {method: 'GET', isArray: false}
    });
})

.factory('CodelistRessource', function($resource, $http) {
    var url = lax_rest_url('codelists');
    var resource = $resource(url, {}, {
        getAllCodelists: {method: 'GET', isArray: true},
        getCodelist: {method: 'GET', url: url + '/:id/entries', isArray: true},
        getPairs: {method: 'GET', url: url + '/:name/pairs', isArray: true},
        create: {method: 'POST'},
        createEntry: {method: 'POST', url: url + '/:id/entries', isArray: true},
        update: {method: 'PUT', url: url + '/:id'},
        updateEntry: {method: 'PUT', url: url + '/:id/entries/:key'},
        delete: {method: 'DELETE', url: url + '/:id'},
        deleteEntryByKey: {method: 'DELETE', url: url + '/:id/entries/:key'}
    });

    resource.deleteEntries = function(id, body) {
        var delUrl = url + '/' + id + '/entries';
        return $http.delete(delUrl, {data: body, headers: {'Content-Type': 'application/json;charset=utf-8'}});
    };

    return resource;
})
.factory('QueryCodelistResource', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('codelists/:id/entries');
})
.factory('BulkSearchResource', function($resource) {
    var baseUrl = lax_rest_url_escaped('bulkSearch/:bulkSearchId');
    return $resource(baseUrl, {
        name: '@name',
        attributeName: '@attributeName'
    });
})
.factory('SystemSettingsResource', function($resource) {
    return $resource(lax_rest_url("initializeSettings"), {});
})
.factory('EnhancedContentRessource', function($resource, $http) {
    var url = lax_rest_url('enhancedContent');
    var resource = $resource(url, {}, {
        getAll: {method: 'GET', isArray: true},
        getById: {method: 'GET', url: url + '/:id'},
        create: {method: 'POST'},
        update: {method: 'PUT', url: url + '/:id'},
        delete: {method: 'DELETE', url: url + '/:id'},
        publish: {method: 'POST', url: url + '/:id/publish'},
        getHtml: {method: 'GET',url: url + '/:id/generate'},
        getLayoutBlockTemplates: {method: 'GET', url: url + '/_layoutBlockTemplates', isArray: true},
        getRetailerList: {method: 'GET', url: url + '/_retailers', isArray: true}
    });

    resource.generatePreview = function(enhancedContent) {
        var generateUrl = url + '/generate';
        return $http.post(generateUrl, enhancedContent);
    };

    return resource;
})
.factory('QueryEnhancedContentResource', function(InfiniteScrollResource) {
    return new InfiniteScrollResource('enhancedContent');
})
.factory('SimpleMappingsResource', function($resource, $http) {
    var url = lax_rest_url('simpleMappings');
    return $resource(url, {}, {
        getAll:        { method: 'GET', isArray: true },
        create:        { method: 'POST' },
        delete:        { method: 'DELETE', url: url + '/:id' },
        update:        { method: 'PUT', url: url + '/:id' },
        getEntries:    { method: 'GET', isArray: true, url: url + '/:id/entries?limit=-1' },
        createEntry:   { method: 'POST', url: url + '/:id/entries/:key' },
        createEntries: { method: 'POST', isArray: true, url: url + '/:id/entries' },
        updateEntry:   { method: 'PUT', url: url + '/:id/entries/:key' },
        deleteEntry:   { method: 'DELETE', url: url + '/:id/entries/:key' },
        // FIXME: Solved in angular +1.6.4, as they interpret the specs such that the DELETE request has no body in prior versions.
        deleteEntries: { method: 'DELETE', url: url + '/:id/entries', hasBody: true }
    });
})
.factory('GdsnPartiesResource', function($resource, $http) {
    var url = lax_rest_url('gdsn/parties');
    return $resource(url, {}, {
        get: { method: 'GET', isArray: true }
    });
})
.factory('AssetFoldersResource', function($resource) {
    return function(path) {

        var url = lax_rest_url('assetFolders');
        if (!_.isEmpty(path)) {
            path = encodeURI(path);
            if (!_.startsWith(path, "/")) {
                path = "/" + path;
            }
        }

        var actions = {
            getRootFolderContents: { method: 'GET', isArray: true },
            getFolderContents:     { method: 'GET', url: url + path, isArray: true },
            createFolder:          { method: 'POST', url: url + path },
            convertToPublicAsset:  { method: 'PUT', url: url + '/_convertToPublicAsset' + path },
            moveAsset:             { method: 'PUT', url: url + '/_moveAsset' + path },
            moveFolder:            { method: 'PUT', url: url + '/_moveFolder' + path },
            renameFolder:          { method: 'PUT', url: url + '/_renameFolder' + path },
            renameAsset:           { method: 'PUT', url: url + '/_renameAsset' + path },
            delete:                { method: 'DELETE', url: url + '/_delete' + path },
            // FIXME: Solved in angular +1.6.4, as they interpret the specs such that the DELETE request has no body in prior versions.
            deleteItems:           { method: 'DELETE', url: url + '/_deleteItems', hasBody: true }
        };

        return $resource(url, {}, actions);
    };
})
.service('ReactBridge', function($modal, $timeout) {

    this.mountPage = function(pageName, mountPoint) {
        var node = document.createElement('div');
        var root = window.reactCreateComponent(node);
        root.render(window.reactPages[pageName]());
        $(mountPoint)[0].appendChild(node);
    };

    this.mountComponent = function(componentName, mountPoint) {
        var node = document.createElement('div');
        var root = window.reactCreateComponent(node);
        root.render(window.reactComponents[componentName]());
        $(mountPoint)[0].appendChild(node);
    };

    this.mountDialog = function(dialogName, mountPoint, props) {
        var node = document.createElement('div');
        var root = window.reactCreateComponent(node, props);
        root.render(window.reactDialogs[dialogName](props));
        $(mountPoint)[0].appendChild(node);

        return root;
    };

    /**
     * Helper function to unmount the React Dialog after displaying the toast for a specified duration.
     */
    this.unmountWhenReady = function(unmount) {
    // Unmount the React Dialog after displaying the toast for a maximum duration of 4 seconds (4000 milliseconds).
    // Ensure unmount is a function before calling it
        if (angular.isFunction(unmount)) {
            $timeout(function() {
                unmount();
            },4000);
        }
    };

    this.showAssetFoldersModal = function(onFileSelected, acceptedTypes) {
        $modal.open({
            templateUrl: 'tpl/asset-folders/modal-asset-folders.tpl.html',
            controller: 'AssetFoldersController',
            backdrop: true,
            size: 'lg',
            windowClass: 'asset-folders-modal',
            resolve: {
                modalParams: function() {
                    return {
                        accept: acceptedTypes,
                        onFileSelected: onFileSelected
                    };
                }
            }
        });
    };
});
